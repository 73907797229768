import { Link } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import { MenuHelper, shortCuts } from "../../uiHelpers/menu.helper";
import { StorageManagerService } from "../../data/storageManager.service";
import "./navigateTo.scss";
import { ReactElement } from "react";
import { TooltipContainer } from "./TooltipContainer";
import i18n from "i18next";
import useTheme from "@mui/material/styles/useTheme";

export const NavigateTo = ({
  text,
  shorCut,
  className,
  isAvailable = true,
  paramters,
  children,
  toolTipIsSingular,
  toolTipText,
  onClick,
  notAvailableChildren,
}: NavigateToProps) => {
  const me = StorageManagerService.getMe();
  var navigateRoute = MenuHelper.getByShortCut(me, shorCut);
  const theme = useTheme();

  if (!navigateRoute || !isAvailable) {
    return notAvailableChildren ?? <span>{text}</span>;
  }

  var containerClass = !!className ? className : "";

  return (
    <TooltipContainer
      text={generateToolTip({ shorCut, toolTipIsSingular, toolTipText })}
    >
      <Link
        className={`navigate-to ${containerClass}`}
        style={{ color: theme.palette.swarm?.navigateTo?.to }}
        to={generateLink(navigateRoute.url, paramters)}
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        <table>
          <tbody>
            <tr>
              <td className="pe-1 icon">
                <LinkIcon
                  style={{ color: theme.palette.swarm?.navigateTo?.icon }}
                />
              </td>
              <td>
                {!!children && children}
                {!!!children && text}
              </td>
            </tr>
          </tbody>
        </table>
      </Link>
    </TooltipContainer>
  );
};

const generateLink = (url: string, paramters?: NavigateToParameters[]) => {
  let result = url;

  if (paramters) {
    let count = 0;
    paramters.forEach((p) => {
      result += `${count === 0 ? "?" : "&"}${p.name}=${p.value}`;
      count++;
    });
  }

  return result;
};

const generateToolTip = ({
  shorCut,
  toolTipIsSingular,
  toolTipText,
}: generateToolTipProps) => {
  let toolTip = toolTipText ?? getShorCutText(shorCut);

  if (toolTipIsSingular && toolTip.endsWith("s") && !toolTipText)
    toolTip = toolTip.substring(0, toolTip.length - 1);

  return `${i18n.t("common.navigation.general")} ${toolTip}`;
};

const getShorCutText = (shorCut: shortCuts) =>
  shorCut === "dashboard-economy"
    ? "economy dashboard"
    : shorCut.replace("-", " ");

interface generateToolTipProps {
  shorCut: shortCuts;
  toolTipIsSingular?: boolean;
  toolTipText?: string;
}

interface NavigateToProps extends generateToolTipProps {
  text?: string;
  className?: string;
  isAvailable?: boolean;
  paramters?: NavigateToParameters[];
  children?: ReactElement | never[];
  notAvailableChildren?: ReactElement | never[];
  onClick?: () => void;
}

export interface NavigateToParameters {
  name: string;
  value: string;
}
