import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import "./kemaroMissions.scss";
import { TableGeneric } from "../../../components/news/TableGeneric/TableGeneric";
import { CrudItemsPanel } from "../../../components/news/CrudItemsPanel";
import { Pagination } from "../../../components/news/Pagination";
import { ToastHelper } from "../../../components/news/Toast.helper";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import {
  ApplicationBar,
  applicationBarRef,
} from "../../../components/news/ApplicationBar";
import { PermissionsHelper } from "../../../uiHelpers/permissionsHelper";
import { KemaroConfigurationDto } from "../../../../../robotcloud-shared/resource-models";
import { KemaroMissionsRequests } from "../../../data/kemaro/kemaroMissions.request";
import { KemaroMissionView } from "./KemaroMissionView";
import { EmailSend } from "../../../components/news/EmailSend";
import { Loading } from "../../../components/Loading";
import { TooltipButton } from "../../../components/news/TooltipButton";
import { BadgesPresenter } from "../../../components/news/BadgesPresenter";
import { TooltipContainer } from "../../../components/news/TooltipContainer";
import { KemaroMissionsFilterModal } from "./KemaroMissionsFilterModal";
import { useTranslation } from "react-i18next";
import { KemaroMissionsNewDialog } from "./newDialog/KemaroMissionsNewDialog";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { tableGenericHandleKeyboardSubjectManager } from "../../../components/news";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { KemaroMissionsCsvZipModal } from "./KemaroMissionsCsvZipModal";
import useTheme from "@mui/material/styles/useTheme";
import { NativeSelect } from "@mui/material";
import { KemaroMissionsColumnDefinition } from "./KemaroMissionsColumnDefinition";
import { useKemaroMissions } from "../../../hooks";

export const KemaroMissions = () => {
  const {
    // State variables
    me,
    currentId,
    currentItem,
    currentTimezoneToShow,
    dataOrder,
    filter,
    items,
    notifications,
    pagination,
    isFetchingList,
    isFetchingItem,
    isFilterDialogOpen,
    isKemaroMissionsCsvZipModalOpen,
    kemaroMissionsCsvZipModalIsWorking,
    openAddMission,

    // Set state functions
    setCurrentId,
    setCurrentTimezoneToShow,
    setDataOrder,
    setIsFilterDialogOpen,
    setIsKemaroMissionsCsvZipModalOpen,
    setOpenAddMission,

    // Configuration variables
    configuration,
    timezonesToShow,
    canTextSearch,
    canSendPdf,
    canDownloadPdf,

    // Handler functions
    badgeClick,
    deleteEntity,
    filterBadgeItems,
    handleShowAllmissionsOfRobot,
    handleDownloadCsv,
    kemaroMissionFilterApply,
    pageChange,
    textSearchChange,
    createHandle,
  } = useKemaroMissions();

  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

  const [canGenerateCsv, setCanGenerateCsv] = useState(true);

  const { t } = useTranslation();
  const [canAddMissions, setCanAddMissions] = useState(false);

  const [showTags, setShowTags] = useState(false);
  const [changeDisplayTime, setChangeDisplayTime] = useState(false);

  const theme = useTheme();
  const columnsDefinition = KemaroMissionsColumnDefinition(showTags, me);

  useEffect(() => {
    setCanAddMissions(
      me?.userAccessType === "Earlybyte" &&
        PermissionsHelper.capableForManualCreationOfMissions()
    );

    setCanGenerateCsv(
      PermissionsHelper.allow("KemaroMissions", "View", "DownloadCsvZip")
    );

    setShowTags(PermissionsHelper.allow("KemaroMissions", "View", "Tags"));

    setChangeDisplayTime(
      PermissionsHelper.allow("KemaroMissions", "View", "ChangeDisplayTime")
    );
  }, [me]);

  const sendEmailhandle = async (account: string) => {
    try {
      await KemaroMissionsRequests.postSendByEmail(currentId!, [
        {
          userName: me?.displayName ?? "",
          emailAddress: account,
        },
      ]);

      ToastHelper.success(`${t("mission.successfullEmail")} ${account}`);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const downloadHandle = async () => {
    if (!currentItem) {
      return;
    }

    setIsDownloadingPdf(true);

    try {
      const pdf = await KemaroMissionsRequests.getPdf(currentId!);

      const decodedData = window.atob(pdf.content);
      const uInt8Array = new Uint8Array(decodedData.length);

      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }

      let blob = new Blob([uInt8Array], { type: "application/pdf" });

      const linkElement = document.createElement("a");
      linkElement.download = pdf.name;
      linkElement.href = URL.createObjectURL(blob);
      linkElement.addEventListener("click", (e) => {
        setTimeout(() => URL.revokeObjectURL(linkElement.href), 30 * 1000);
      });
      linkElement.click();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsDownloadingPdf(false);
  };

  const refAapplicationBar = useRef<applicationBarRef>(null);

  return (
    <Box className="page-content">
      <Box className="page-header">
        <ApplicationBar
          ref={refAapplicationBar}
          title={t("mission.title")}
          canSearch={canTextSearch}
          search={textSearchChange}
        >
          <>
            {!canTextSearch && (
              <TooltipContainer
                content={
                  <table className="tool-tip-text">
                    <tbody>
                      <tr>
                        <td className="no-wrap">
                          {t("mission.filterDuration", {
                            duration:
                              (configuration as KemaroConfigurationDto)
                                ?.missions?.monthRange ?? "",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("mission.mightImpact")}</td>
                      </tr>
                    </tbody>
                  </table>
                }
              >
                <div className="me-2 px-2 py-1 rounded-2 border-white-1">
                  <WarningAmberOutlinedIcon className="me-2" />
                  {t("mission.performanceImpacted")}
                </div>
              </TooltipContainer>
            )}

            <TooltipButton
              className="button"
              tooltip={t("mission.filters")}
              onClick={() => setIsFilterDialogOpen(true)}
            >
              <FilterAltOutlinedIcon />
            </TooltipButton>
          </>
        </ApplicationBar>
      </Box>
      <div className="page-body d-flex kemaro-missions-body">
        <div
          className="kemaro-missions-left-side"
          style={{
            borderRight:
              theme.palette.swarm?.kemaroMissions?.bodyLeftSideBorderRight,
          }}
        >
          <CrudItemsPanel
            title={t("mission.reports")}
            titleRightSide={
              <>
                {canAddMissions && (
                  <>
                    <TooltipButton
                      aria-label="DEV: add mission"
                      tooltip={t("mission.add.tooltip")}
                      onClick={() => setOpenAddMission(true)}
                    >
                      <PostAddOutlinedIcon
                        className="earlybyte-pink-color"
                        sx={{ color: "swarm.general.earlybytePinkColor" }}
                      />
                    </TooltipButton>

                    {openAddMission && (
                      <KemaroMissionsNewDialog
                        openPopup={openAddMission}
                        onCreated={createHandle}
                        onCancel={() => {
                          setOpenAddMission(false);
                          tableGenericHandleKeyboardSubjectManager.setData({
                            caller: "KemaroMissions",
                            handleKeyboard: false,
                          });
                        }}
                      />
                    )}
                  </>
                )}

                {canGenerateCsv && (
                  <TooltipButton
                    aria-label={t("robot.csv.tooltip")}
                    tooltip={t("robot.csv.tooltip")}
                    onClick={() => setIsKemaroMissionsCsvZipModalOpen(true)}
                  >
                    <DownloadOutlinedIcon
                      className="primary-text"
                      sx={{ color: "swarm.general.primaryText" }}
                    />
                  </TooltipButton>
                )}

                {changeDisplayTime && (
                  <div className="float-end pt-2 me-3">
                    <span className="me-2 display-timezone-title ">
                      {t("mission.filter.displayTime.title")}
                    </span>
                    <NativeSelect
                      className="drop-down-title"
                      value={currentTimezoneToShow.value}
                      onChange={(event) =>
                        setCurrentTimezoneToShow(
                          timezonesToShow.filter(
                            (x) => x.value === event.target.value
                          )[0]
                        )
                      }
                    >
                      {timezonesToShow.map((x) => (
                        <option key={x.value} value={x.value}>
                          {x.text}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                )}

                <BadgesPresenter
                  className="mt-3"
                  items={filterBadgeItems}
                  itemClick={(x) => badgeClick(x, refAapplicationBar)}
                />
              </>
            }
            createToolTip=""
            containerClassName="kemaro-missions-list-items"
            isFetching={isFetchingList}
            items={items}
            canCreate={false}
            onCreateClick={() => {}}
          >
            <TableGeneric
              tableClassName=""
              showHeader={true}
              columns={columnsDefinition}
              items={items ?? []}
              idProperty="id"
              headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
              selectionChanged={(item) => setCurrentId(item.id)}
              itemsOrder={dataOrder}
              selectId={currentItem?.id}
              isAwaiting={isFetchingItem}
              captureKeyboard
            />
          </CrudItemsPanel>

          {isKemaroMissionsCsvZipModalOpen && (
            <KemaroMissionsCsvZipModal
              mapsDisabled={
                (configuration as KemaroConfigurationDto).missions
                  .mapsDownloadLimit <= pagination.totalItems
              }
              maxQuantity={
                (configuration as KemaroConfigurationDto).missions
                  .mapsDownloadLimit
              }
              onClose={() => setIsKemaroMissionsCsvZipModalOpen(false)}
              isWorking={kemaroMissionsCsvZipModalIsWorking}
              onCsv={() => handleDownloadCsv(false)}
              onZip={() => handleDownloadCsv(true)}
            />
          )}

          <Pagination
            containerClassName="kemaro-missions-list-pager"
            pagination={pagination}
            isFetching={isFetchingList}
            onChange={pageChange}
          />
        </div>

        <div className="flex-fill">
          {currentItem && (
            <>
              <div
                className={
                  "kemaro-missions-view-container " +
                  (!canDownloadPdf && !canSendPdf
                    ? "kemaro-missions-view-container-h100"
                    : "")
                }
              >
                <KemaroMissionView
                  item={currentItem}
                  isFetching={isFetchingItem}
                  onDelete={deleteEntity}
                  onShowAllmissionsOfRobot={handleShowAllmissionsOfRobot}
                />
              </div>

              {canDownloadPdf || canSendPdf ? (
                <div
                  className="w-100 send-report px-3 pt-2"
                  style={{
                    borderTop:
                      theme.palette.swarm?.kemaroMissions?.sendReportBorderTop,
                  }}
                >
                  <style>
                    {`[data-hover="sendreport"]:hover {
                      background: ${theme.palette.swarm?.kemaroMissions?.sendReportActionButtonBackgroundHover} !important;
                      color: ${theme.palette.swarm?.kemaroMissions?.sendReportActionButtonHover} !important;
                    }`}
                  </style>
                  {canSendPdf ? (
                    <EmailSend
                      emailAccounts={notifications}
                      send={sendEmailhandle}
                    >
                      {canDownloadPdf ? (
                        isDownloadingPdf ? (
                          <div
                            className="btn action-button no-background"
                            style={{
                              color:
                                theme.palette.swarm?.kemaroMissions
                                  ?.sendReportActionButton,
                            }}
                          >
                            <Loading isLoading={isDownloadingPdf} size={25} />
                          </div>
                        ) : (
                          <button
                            onClick={() => downloadHandle()}
                            className="btn action-button button-with-text"
                            style={{
                              color:
                                theme.palette.swarm?.kemaroMissions
                                  ?.sendReportActionButton,
                              background:
                                theme.palette.swarm?.kemaroMissions
                                  ?.sendReportActionButtonBackground,
                            }}
                            data-hover={"sendreport"}
                          >
                            {t("common.buttons.download")}
                          </button>
                        )
                      ) : (
                        <></>
                      )}
                    </EmailSend>
                  ) : canDownloadPdf ? (
                    isDownloadingPdf ? (
                      <div
                        className="btn action-button no-background  float-end"
                        style={{
                          color:
                            theme.palette.swarm?.kemaroMissions
                              ?.sendReportActionButton,
                        }}
                      >
                        <Loading isLoading={isDownloadingPdf} size={25} />
                      </div>
                    ) : (
                      <button
                        onClick={() => downloadHandle()}
                        className="btn action-button float-end button-with-text"
                        style={{
                          color:
                            theme.palette.swarm?.kemaroMissions
                              ?.sendReportActionButton,
                          background:
                            theme.palette.swarm?.kemaroMissions
                              ?.sendReportActionButtonBackground,
                        }}
                        data-hover={"sendreport"}
                      >
                        {t("common.buttons.download")}
                      </button>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}

          {isFilterDialogOpen && (
            <KemaroMissionsFilterModal
              open={isFilterDialogOpen}
              filter={filter}
              cancel={() => setIsFilterDialogOpen(false)}
              apply={kemaroMissionFilterApply}
            />
          )}
        </div>
      </div>
    </Box>
  );
};
