import { useState, useEffect } from "react";
import { SearchTextDtoOfGuid } from "../../../robotcloud-shared/resource-models";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { ToastHelper } from "../components/news";

export const useTextSearch = <
  T,
  U extends { searchedText: string | null; items: T[] }
>(
  fetchFunction: (
    searchTextDto: SearchTextDtoOfGuid,
    manufacturerId?: string | null | undefined
  ) => Promise<U>,
  initialData: T[],
  clearAfterSelect: boolean,
  processData: (data: T[]) => T[] = (data) => data,
  blackList: string[] | undefined,
  currentId: string | undefined,
  selectionChange: (newValue: T | undefined) => void,
  manufacturerId?: string
) => {
  const [items, setItems] = useState<T[]>(initialData);
  const [currentItem, setCurrentItem] = useState<T | undefined>(undefined);
  const [searchText, setSearchText] = useState("");
  const [lastSearch, setLastSearch] = useState<SearchTextDtoOfGuid | null>(
    null
  );

  useEffect(() => {
    const textSearch: SearchTextDtoOfGuid = {
      textToSearch: searchText,
      id: currentId ?? null,
      maxResults: null,
      blackList: blackList ?? null,
    };

    if (JSON.stringify(textSearch) === JSON.stringify(lastSearch)) {
      return;
    }

    setLastSearch(textSearch);
    const fetchPromise = manufacturerId
      ? fetchFunction(textSearch, manufacturerId)
      : fetchFunction(textSearch);
    fetchPromise
      .then((result) => {
        if (result.searchedText === searchText) {
          const data = processData(result.items);
          setItems(data);

          if (currentId) {
            setCurrentItem(data.find((x) => (x as any).id === currentId));
          }
        }
      })
      .catch((error) => {
        ToastHelper.errors(ErrorHelper.process(error));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, blackList, currentId]);

  const handleSelectionChange = (
    newValue: string | null | undefined,
    propertyToSearch: string
  ) => {
    const selected = newValue
      ? items.find((x) => (x as unknown as any)[propertyToSearch] === newValue)
      : undefined;

    setCurrentItem(clearAfterSelect ? undefined : selected);
    selectionChange(selected);

    if (clearAfterSelect) {
      setLastSearch(null);
      setSearchText("");
    }
  };

  return {
    items,
    currentItem,
    setCurrentItem,
    searchText,
    setSearchText,
    lastSearch,
    setLastSearch,
    handleSelectionChange,
  };
};
