import { ToastHelper, TooltipContainer } from "../../news";
import { GeneralFunctions } from "../../../views/generalFunctions";
import { KemaroRobotTagSearch } from "./KemaroRobotTagSearch";
import { DeleteForever } from "@mui/icons-material";
import "./KemaroRobotTagsList.scss";
import { useTheme } from "@mui/material/styles";

export const KemaroRobotTagsList = ({
  addToolTip,
  removeTootTip,
  tags,
  canAddTag,
  itemsShowAs = "List",
  regularExpression,
  invalidText,
  placeHolderText,
  onChange,
}: KemaroRobotTagsListProps) => {
  const addText = (newText: string) => {
    const text = newText.trim();

    if (regularExpression && !regularExpression.test(text)) {
      ToastHelper.error(invalidText ?? "Invalid text");
      return;
    }

    if (!tags.some((x) => x === text) && !GeneralFunctions.isBlank(text)) {
      const texts = [...tags, text].sort((a, b) => {
        if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) return 1;
        if (b.toLocaleLowerCase() > a.toLocaleLowerCase()) return -1;
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      });

      onChange(texts);
    }
  };

  const removeText = (text: string) => {
    const texts = [...tags.filter((x) => x !== text)];
    onChange(texts);
  };

  // using <badgesPresenter> colors instead of <kemaroRobotTagsList> colors, for consistency
  const badgesPresenter = useTheme().palette.swarm?.badgesPresenter;

  return (
    <div className="row kemaro-robot-tags-list">
      <div className={`col-12 ${itemsShowAs === "List" ? "mb-3" : "mb-0"} `}>
        <KemaroRobotTagSearch
          selected={addText}
          usedTags={tags}
          canAdd={canAddTag}
          placeHolderText={placeHolderText}
        />
      </div>

      {itemsShowAs === "List" && (
        <div className="col-12 mb-3 text-container">
          <table className="table table-borderless table-striped">
            <tbody>
              {tags.map((x, idx) => (
                <tr key={idx}>
                  <td className="align-content-center">{x}</td>
                  <td className="td-delete">
                    <TooltipContainer text={removeTootTip}>
                      <DeleteForever
                        className="icon-as-buttom"
                        color="error"
                        onClick={() => removeText(x)}
                      />
                    </TooltipContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {itemsShowAs === "InLine" && tags.length > 0 && (
        <div className="col-12 text-container d-flex flex-wrap">
          {tags.map((x) => (
            <span
              key={x}
              className="badge mt-1 mb-2 me-2 py-1 rounded-pill"
              onClick={() => removeText(x)}
              style={{
                color: badgesPresenter?.roundedPillTag,
                borderColor: badgesPresenter?.roundedPillTagBorder,
              }}
              data-hover="tag"
            >
              <style>
                {`[data-hover="tag"]:hover {
              background: ${badgesPresenter?.roundedPillTagHoverBackground}
              }`}
              </style>
              {x}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

interface KemaroRobotTagsListProps {
  addToolTip: string;
  removeTootTip: string;
  tags: string[];
  canAddTag: boolean;
  itemsShowAs?: KemaroRobotTagsListItemsShow;
  regularExpression?: RegExp;
  invalidText?: string;
  placeHolderText?: string;
  onChange: (texts: string[]) => void;
}

type KemaroRobotTagsListItemsShow = "List" | "InLine";
