import { ReactElement, useState } from "react";
import "./treeVew.scss";
import { useTheme } from "@mui/material/styles";

export function TreeVew<T>(props: TreeVewProps<T>) {
  const [currentItem, setCurrentItem] = useState<any | undefined>(undefined);

  const onClick = (item: any) => {
    const selected = item as T;

    setCurrentItem(selected);

    if (props.nodeClick) props.nodeClick(selected);
  };

  return GenerateContent({
    idProperty: props.idProperty,
    childNodesProperty: props.childNodesProperty,
    textProperty: props.textProperty,
    template: props.template,
    allNodesExpanded: props.allNodesExpanded ?? "true",
    items: props.items,
    selectedId: currentItem ? currentItem[props.idProperty] : undefined,
    nodeClick: (x) => onClick(x),
  });
}

export type nodesExpandedType = "true" | "false";

interface TreeVewProps<T> {
  idProperty: string;
  childNodesProperty: string;
  textProperty?: string;
  template?: (item: any) => ReactElement;
  allNodesExpanded?: nodesExpandedType;
  items: T[];
  nodeClick?: (item: T | undefined) => void;
}

const GenerateContent = (props: GenerateContentProps) => {
  return (
    <div className="accordion tree-view-accordion">
      {props.items.map((x) => (
        <Node
          key={x[props.idProperty]}
          idProperty={props.idProperty}
          childNodesProperty={props.childNodesProperty}
          textProperty={props.textProperty}
          template={props.template}
          expanded={props.allNodesExpanded}
          item={x}
          selectedId={props.selectedId}
          nodeClick={(x) => props.nodeClick(x)}
        />
      ))}
    </div>
  );
};

interface GenerateContentProps {
  idProperty: string;
  childNodesProperty: string;

  textProperty?: string;
  template?: (item: any) => ReactElement;

  allNodesExpanded: nodesExpandedType;
  items: any[];

  selectedId: string | undefined;
  nodeClick: (item: any) => void;
}

const Node = (props: NodeProps) => {
  const [expanded, setExpanded] = useState(props.expanded);

  const hasChildren =
    !!props.item[props.childNodesProperty] &&
    (props.item[props.childNodesProperty] as any[]).length > 0;

  const hasSelected =
    props.selectedId && props.item[props.idProperty] === props.selectedId;

  const theme = useTheme();

  return (
    <div
      className={`accordion-item ${
        hasChildren ? "accordion-icon-tree-view" : "accordion-no-icon"
      } `}
    >
      <table
        className={`table table-borderless mb-0 ${
          hasSelected ? "node-header-selected" : "node-header"
        }`}
        style={
          hasSelected
            ? {
                backgroundColor:
                  theme.palette.swarm?.treeView?.nodeHeaderSelectedBackground,
              }
            : {}
        }
        data-hover={"treenode"}
        id={`panelsStayOpen-headingOne-${props.item[props.idProperty]}`}
      >
        <style>{`[data-hover="treenode"]:hover {
        background: ${theme.palette.swarm?.treeView?.nodeHeaderHoverBackground}
    }`}</style>
        <tbody>
          <tr>
            <td className="td-icon py-1 align-middle">
              {hasChildren && (
                <button
                  className={`accordion-button py-0 icon-button ${
                    expanded === "true" ? "" : "collapsed"
                  }`}
                  style={{
                    backgroundColor:
                      theme.palette.swarm?.treeView?.iconButtonBackground,
                    filter: theme.palette.swarm?.treeView?.acccordeonButton,
                  }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#panelsStayOpen-collapseOne-${
                    props.item[props.idProperty]
                  }`}
                  aria-expanded={expanded}
                  aria-controls={`panelsStayOpen-collapseOne-${
                    props.item[props.idProperty]
                  }`}
                  onClick={() =>
                    setExpanded(expanded === "true" ? "false" : "true")
                  }
                ></button>
              )}
            </td>

            <td className=" py-1" onClick={() => props.nodeClick(props.item)}>
              {props.template && props.template(props.item)}
              {props.textProperty && !!!props.template && (
                <span>{props.item[props.textProperty]} </span>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <div
        id={`panelsStayOpen-collapseOne-${props.item[props.idProperty]}`}
        className={`accordion-collapse collapse ${
          expanded === "true" ? "show" : ""
        }`}
        aria-labelledby={`panelsStayOpen-headingOne-${
          props.item[props.idProperty]
        }`}
      >
        <div className="accordion-body pe-0 py-0 ps-4">
          {(props.item[props.childNodesProperty] as any[]).length > 0 &&
            GenerateContent({
              idProperty: props.idProperty,
              childNodesProperty: props.childNodesProperty,
              textProperty: props.textProperty,
              template: props.template,
              allNodesExpanded: props.expanded,
              items: props.item[props.childNodesProperty],
              selectedId: props.selectedId,
              nodeClick: (x) => props.nodeClick(x),
            })}
        </div>
      </div>
    </div>
  );
};

interface NodeProps {
  idProperty: string;
  childNodesProperty: string;
  textProperty?: string;
  template?: (item: any) => ReactElement;
  expanded: nodesExpandedType;
  item: any;
  selectedId: string | undefined;
  nodeClick: (item: any) => void;
}
