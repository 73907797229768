import { useState } from "react";
import { Loading } from "../../components/Loading";
import { ApplicationBar } from "../../components/news/ApplicationBar";
import { ToastHelper } from "../../components/news/Toast.helper";
import { useTranslation } from "react-i18next";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import "./profile.scss";
import Edit from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/Key";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { UserNotificationConfigurations } from "../shared/users/UserNotificationConfigurations";
import { ProfileEditModal } from "./ProfileEditModal";
import { ProfilePasswordModal } from "./ProfilePasswordModal";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { TooltipButton } from "../../components/news/TooltipButton";
import { StorageManagerService } from "../../data/storageManager.service";
import { GeneralFunctions } from "../generalFunctions";
import useTheme from "@mui/material/styles/useTheme";
import { ProcessesRequests } from "../../data/processes.request";
import { YesNoOkDialog } from "../../components/news";
import { processLinkIdKey } from "../mainPanel/MainPanel";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
export const Profile = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const createAccountMigrationProcessLink = async () => {
    try {
      const processLinkId =
        await ProcessesRequests.createUserMigrationProcessLink();
      localStorage.setItem(processLinkIdKey, processLinkId);
      navigate("/logout");
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const {
    user,
    isKemaroAcademySection,
    isModifyModalOpen,
    setIsModifyModalOpen,
    handleNotificationConfigurationSave,
    handleNotificationConfigurationDelete,
    handleNotificationConfigurationTest,
    isMigrateAccountModalOpen,
    setIsMigrateAccountModalOpen,
    isModifyModalChangePassword,
    setIsModifyModalChangePassword,
    canCopyApiToken,
    canMigrateAccount,
    modifyMeSave,
  } = useUser(setIsLoading);
  const copyApiToken = async () => {
    const apiTokenContianer = await StorageManagerService.getApiToken();
    navigator.clipboard.writeText(`Bearer ${apiTokenContianer.token}`);

    ToastHelper.success(
      `${t("profile.copyTokenMessage")}${GeneralFunctions.dateToTimeDateString(
        apiTokenContianer.expire
      )}`
    );
  };

  return (
    <div className="page-content">
      <div className="page-header">
        <ApplicationBar title={t("profile.title")} canSearch={false} />
      </div>

      <div className="page-body profile-body">
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : !user ? (
          <></>
        ) : (
          <>
            <section>
              <p className="title">{t("profile.account")}</p>
              <div className="row">
                <div className="col-12 pt-4 ps-5 mb-3 section-container">
                  <p className="pb-0 section-title me-5">
                    {user.firstName} {user.lastName}
                  </p>

                  <TooltipButton
                    aria-label="change password"
                    tooltip={t("profile.changePassword.tooltip")}
                    disabled={!user.userName?.endsWith("@eb8.ch")}
                    onClick={() => setIsModifyModalChangePassword(true)}
                  >
                    <KeyIcon
                      color={
                        user.userName?.endsWith("@eb8.ch")
                          ? "primary"
                          : "disabled"
                      }
                    />
                  </TooltipButton>
                  <TooltipButton
                    aria-label="edit"
                    tooltip={t("profile.edit.tooltip")}
                    onClick={() => setIsModifyModalOpen(true)}
                  >
                    <Edit color="primary" />
                  </TooltipButton>
                  {canCopyApiToken && (
                    <TooltipButton
                      aria-label="copy api token"
                      tooltip={t("profile.copyApiToken")}
                      onClick={copyApiToken}
                    >
                      <FingerprintIcon color="primary" />
                    </TooltipButton>
                  )}
                  {canMigrateAccount && (
                    <TooltipButton
                      aria-label="migrate account"
                      tooltip={t("profile.migrateAccount")}
                      onClick={() => {
                        setIsMigrateAccountModalOpen(true);
                      }}
                    >
                      <ManageAccountsIcon color="primary" />
                    </TooltipButton>
                  )}
                </div>
                <div className="col-12 ps-5 mb-2 option-container">
                  <span className="option-subtitle">
                    {`${t("profile.userName")} / ${t(
                      "user.primaryEmailAddress"
                    )}`}
                  </span>
                  {user.userName ? user.userName : user.primaryEmailAddress}
                </div>

                <div className="col-12 ps-5 mb-2 option-container">
                  <span className="option-subtitle">
                    {t("profile.companyName")}
                  </span>
                  {user.accessName}
                </div>

                <div className="col-12 ps-5 mb-2 option-container">
                  <span className="option-subtitle">{t("profile.type")}</span>
                  {user.accessType}
                </div>
              </div>

              <div className="row mt-5 mb-2">
                <div className="col-12 ps-5 option-container mb-3">
                  <span className="option-title w-14-em">
                    {t("profile.passwordResetEmail")}
                  </span>
                  <span className="option-title">
                    {user.passwordResetEmailAddress}
                  </span>
                </div>

                <div className="col-12 ps-5 option-container mb-3">
                  <span className="option-title w-14-em">
                    {t("profile.timezone")}
                  </span>
                  <span className="option-title">{user.windowsTimeZoneId}</span>
                </div>

                <div className="col-12 ps-5 option-container mb-3">
                  <span className="option-title w-14-em">
                    {t("profile.language")}
                  </span>
                  <span className="option-title">
                    {t(`profile.languages.${user.language}` as const)}
                  </span>
                </div>

                <div className="col-12 ps-5 option-container mb-3">
                  <span className="option-title w-14-em">
                    {t("profile.defaultPage")}
                  </span>
                  <span className="option-title">
                    {(user.defaultPage ?? " ") === " "
                      ? " "
                      : t(
                          `${
                            `profile.edit.defaultPage.${user
                              .defaultPage!.replace(/[/ ]/g, "")
                              .replace(
                                /^./,
                                user.defaultPage![0].toLowerCase()
                              )}` as const
                          }` as const
                        )}
                  </span>
                </div>
              </div>

              {isModifyModalOpen && (
                <ProfileEditModal
                  openPopup={isModifyModalOpen}
                  item={user}
                  onSave={modifyMeSave}
                  onCancel={() => setIsModifyModalOpen(false)}
                />
              )}

              {isModifyModalChangePassword && (
                <ProfilePasswordModal
                  userId={user.id}
                  isOpenModal={isModifyModalChangePassword}
                  onCancel={() => setIsModifyModalChangePassword(false)}
                  onSaved={() => setIsModifyModalChangePassword(false)}
                />
              )}

              {isMigrateAccountModalOpen && (
                <YesNoOkDialog
                  open={isMigrateAccountModalOpen}
                  size="md"
                  type="yesNo"
                  title={t("profile.migrateAccount")}
                  message={t("profile.migrateAccountInstructions")}
                  onYes={() => {
                    createAccountMigrationProcessLink();
                    setIsMigrateAccountModalOpen(false);
                  }}
                  onNo={() => {
                    setIsMigrateAccountModalOpen(false);
                  }}
                  isWorking={false}
                />
              )}
            </section>

            <div
              className="separator"
              style={{
                borderBottom:
                  theme.palette.swarm?.profile?.bodySeparatorBorderBottom,
              }}
            />

            <section className="mt-4">
              <p className="title">{t("profile.reporting")}</p>
              <div className="row">
                <div className="col-12 ps-5">
                  <UserNotificationConfigurations
                    notifications={user.notificationConfigurations}
                    onSave={handleNotificationConfigurationSave}
                    onDelete={handleNotificationConfigurationDelete}
                    onTest={handleNotificationConfigurationTest}
                  />
                </div>
              </div>
            </section>

            {isKemaroAcademySection && (
              <>
                <div
                  className="separator"
                  style={{
                    borderBottom:
                      theme.palette.swarm?.profile?.bodySeparatorBorderBottom,
                  }}
                ></div>

                <section className="mt-4">
                  <p className="title">{t("kemaroAcademy.title")}</p>
                  <div className="row ps-5 pt-4">
                    <div className="col-6">
                      <span className="fw-bold pe-3">
                        {t("kemaroAcademy.language")}
                      </span>
                      {user.maxbrainConfig.language}
                    </div>

                    {user.maxbrainConfig.group && (
                      <div className="col-6">
                        <span className="fw-bold pe-3">
                          {t("kemaroAcademy.info.group")}
                        </span>
                        {user.maxbrainConfig.group}
                      </div>
                    )}
                  </div>
                </section>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
