import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import { ReactElement } from "react";
import { Loading } from "../Loading";
import { TooltipButton } from "./TooltipButton";
import { NoItems } from "./NoItems";

export function CrudItemsPanel<T>({
  containerClassName,
  title,
  titleContainerClassName = "header-line",
  titleLeftSide,
  titleRightSide,
  isFetching,
  items,
  children,
  canCreate,
  createToolTip,
  hideNoItems,
  onCreateClick,
  onClick,
}: crudItemsPanelProps<T>) {
  return (
    <div
      className={containerClassName}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div className={titleContainerClassName}>
        {titleLeftSide}
        {title.length > 0 && <span className="pe-2">{title}</span>}
        {canCreate && (
          <TooltipButton
            onClick={() => onCreateClick()}
            tooltip={createToolTip ?? ""}
          >
            <PlaylistAdd color="primary" fontSize="medium" />
          </TooltipButton>
        )}
        {titleRightSide}
      </div>

      {isFetching ? (
        <Loading isLoading={isFetching} />
      ) : (
        <>
          {!items || items.length === 0 ? (
            <>{!hideNoItems && <NoItems />}</>
          ) : (
            children
          )}
        </>
      )}
    </div>
  );
}

interface crudItemsPanelProps<T> {
  containerClassName: string;
  title: string;
  titleContainerClassName?: string;
  titleLeftSide?: ReactElement | never[];
  titleRightSide?: ReactElement | never[];
  isFetching: boolean;
  items: T[] | undefined;
  children: ReactElement | never[];
  canCreate: boolean;
  createToolTip?: string;
  hideNoItems?: boolean;
  onCreateClick: () => void;
  onClick?: () => void;
}
