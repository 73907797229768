import { Button, Dialog, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { ReactElement, useEffect } from "react";
import { Loading } from "../Loading";
import { tableGenericHandleKeyboardSubjectManager } from "./TableGeneric";

export const YesNoOkDialog = (props: yesNoOkDialogProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.open) handleTableGenericHandleKeyboard(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const handleTableGenericHandleKeyboard = (yesNo: boolean) => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "YesNoOkDialog",
      handleKeyboard: yesNo,
    });
  };

  return (
    <Dialog
      className={`application-modal ${props.modalClassName ?? ""}`}
      open={props.open}
      maxWidth={props.size}
      onClose={() => {
        handleTableGenericHandleKeyboard(false);
        if (props.onNo) {
          props.onNo();
        } else if (props.onOk) {
          props.onOk();
        }
      }}
    >
      <div className="modal-content">
        <Stack className={props.className}>
          <Box className="application-modal-header">
            <h3 className="modal-title">{props.title}</h3>
          </Box>
          <Box className="application-modal-body">
            <Grid container>
              {props.children ? (
                props.children
              ) : (
                <Grid className="input-label mb-3 text-linebreak" item xs={12}>
                  {props.message}
                </Grid>
              )}
            </Grid>
          </Box>
          {props.isWorking ? (
            <Box className={`application-modal-footer pt-4 pb-5`}>
              <Loading isLoading={props.isWorking} />
            </Box>
          ) : (
            <Box className="application-modal-footer">
              {props.type === "yesNo" && (
                <>
                  <Button
                    variant="contained"
                    className="button-with-text"
                    color="primary"
                    onClick={() => {
                      handleTableGenericHandleKeyboard(false);
                      if (props.onYes) {
                        props.onYes();
                      }
                    }}
                  >
                    {!!props.yesText ? props.yesText : t("common.buttons.yes")}
                  </Button>

                  <Button
                    className="modal-cancel-button button-with-text"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      handleTableGenericHandleKeyboard(false);
                      if (props.onNo) {
                        props.onNo();
                      }
                    }}
                  >
                    {!!props.noText ? props.noText : t("common.buttons.no")}
                  </Button>
                </>
              )}

              {props.type === "ok" && (
                <Button
                  className="button-with-text"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleTableGenericHandleKeyboard(false);
                    if (props.onOk) {
                      props.onOk();
                    }
                  }}
                >
                  {!!props.okText ? props.okText : t("common.buttons.ok")}
                </Button>
              )}
            </Box>
          )}
        </Stack>
      </div>
    </Dialog>
  );
};

export interface yesNoOkDialogProps {
  open: boolean;
  size: "xs" | "sm" | "md" | "lg" | "xl";
  title: string;
  message?: string;
  isWorking?: boolean;
  type: "yesNo" | "ok";
  yesText?: string;
  noText?: string;
  okText?: string;
  onYes?: () => void;
  onNo?: () => void;
  onOk?: () => void;
  children?: ReactElement | never[];
  className?: string;
  modalClassName?: string;
}
