import { ReactElement } from "react";
import { Tooltip } from "@mui/material";

export const TooltipContainer = ({
  text,
  content,
  children,
  className = "tool-tip-text",
  placement = "top-end",
  enterDelay = 1000,
  leaveDelay = 200,
}: tooltipContainerProps) => {
  return (
    <Tooltip
      title={content ? content : <span className={className}>{text}</span>}
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
    >
      <div
        style={{
          display: "inline",
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

interface tooltipContainerProps {
  text?: string;
  content?: ReactElement;
  children: ReactElement;
  className?: string;
  placement?: toolTipPlacement;
  enterDelay?: number;
  leaveDelay?: number;
}

export type toolTipPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";
