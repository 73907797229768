import { ReactElement } from "react";
import Edit from "@mui/icons-material/Edit";
import DeleteForever from "@mui/icons-material/DeleteForever";
import "./viewHeader.scss";
import { TooltipButton } from "./TooltipButton";
import { ShareIdButton } from "./ShareIdButton";
import { useTranslation } from "react-i18next";

export const ViewHeader = ({
  title,
  titleIcon = null,
  copyLinkId,
  canEdit = true,
  canDelete = true,
  children = null,
  onEdit,
  onDelete,
  className,
}: viewHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className={`view-header ${className ?? "px-0"}`}>
      <table className="w-100">
        <tbody>
          <tr>
            {titleIcon && <td className="w-0 pe-2">{titleIcon}</td>}

            <td className="w-0">
              <span className={"title"}>{title}</span>
            </td>
            <td className="w-0 no-wrap">
              {copyLinkId && <ShareIdButton id={copyLinkId} />}

              {canEdit && (
                <TooltipButton
                  aria-label="edit"
                  tooltip={t("viewHeaderButtons.edit")}
                  onClick={onEdit}
                >
                  <Edit color="primary" />
                </TooltipButton>
              )}

              {canDelete && (
                <TooltipButton
                  aria-label="delete"
                  tooltip={t("viewHeaderButtons.delete")}
                  onClick={onDelete}
                >
                  <DeleteForever color="error" />
                </TooltipButton>
              )}
            </td>
            <td>{children}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export interface viewHeaderProps {
  title: string;
  titleIcon?: ReactElement | never[] | null;
  copyLinkId?: string;
  canEdit?: boolean;
  canDelete?: boolean;
  children: ReactElement | never[] | null;
  onEdit?: () => void;
  onDelete?: () => void;
  className?: string;
}
