import { Button, Dialog } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../../../components/Loading";
import {
  Banner,
  TableGeneric,
  ToastHelper,
} from "../../../../../components/news";
import { ErrorHelper } from "../../../../../uiHelpers/errors.helper";
import {
  CompanyStructureReducedDto,
  CustomerDto,
  DealerDto,
  KemaroRobotDeploymentContainerDto,
  KemaroRobotDeploymentPostDto,
} from "../../../../../../../robotcloud-shared/resource-models";
import { kemaroRobotDeploymentSave } from "../../../../../data/kemaro/kemaroRobotDeployment.request";
import { DealerSearch } from "../../../../../components/search/DealerSearch";
import { GeneralFunctions } from "../../../../generalFunctions";
import { CustomerSearch } from "../../../../../components/search/CustomerSearch";
import { CompanyStructureSearch } from "../../../../../components/search/CompanyStructureSearch";
import { kemaroRobotDeploymentModalColumnsDefinition } from "./KemaroRobotDeploymentModalColumnsDefinition";
import { CompanyStructureRequests } from "../../../../../data/companyStructure.request";
import {
  KemaroRobotDeploymentModalIds,
  kemaroRobotDeploymentModalIdsdefault,
} from "./models";
import "./KemaroRobotDeploymentModal.scss";
import KemaroRobotContext from "../../KemaroRobotContext";
import { PermissionsHelper } from "../../../../../uiHelpers/permissionsHelper";
import { useKemaroRobotDeploymentData } from "../../../../../hooks";

export const KemaroRobotDeploymentModal = (
  props: KemaroRobotDeploymentModalProps
) => {
  const { t } = useTranslation();

  const { refreshItems, refreshItem } = useContext(KemaroRobotContext);

  const [canSetDealer, setCanSetDealer] = useState(false);
  const [canSetCustomer, setCanSetCustomer] = useState(false);
  const [canSetLocation, setCanSetLocation] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const { data, setData, idOriginalCustomer, isLoading, setIsLoading } =
    useKemaroRobotDeploymentData(props.kemaroRobotId);
  const [dataOrigin, setDataOrigin] = useState<KemaroRobotDeploymentModalIds>({
    ...kemaroRobotDeploymentModalIdsdefault,
  });
  const [idLocation, setIdLocation] = useState<string>(idLocationDefault);

  useEffect(() => {
    setPermissions();
  }, [props.kemaroRobotId]);

  const handleSave = async () => {
    setIsLoading(true);

    try {
      const postDto: KemaroRobotDeploymentPostDto = {
        idKemaroRobot: props.kemaroRobotId,
        idDealer: data?.idDealer ?? null,
        idCustomer: data?.idCustomer ?? null,
        idLocation: GeneralFunctions.isBlank(idLocation) ? null : idLocation,
      };

      const newData = await kemaroRobotDeploymentSave(postDto);

      dataChange(newData, setData, setDataOrigin);
      setIdLocation(idLocationDefault);
      canSaveChange(newData, idLocationDefault);

      refreshItem();
      refreshItems(undefined, false, false);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsLoading(false);
  };

  const dataChange = (
    newData: KemaroRobotDeploymentContainerDto,
    setData: (newData: KemaroRobotDeploymentContainerDto) => void,
    setDataOrigin: (newDo: KemaroRobotDeploymentModalIds) => void
  ) => {
    setData(newData);

    setDataOrigin({
      idDealer: newData.idDealer,
      idCustomer: newData.idCustomer,
      idLocation:
        newData.location.length > 0 ? newData.location[0].locationId : null,
    });
  };

  const dealerChange = (newDealer: DealerDto | undefined) => {
    const newIdLocation = idLocationDefault;
    const newData: KemaroRobotDeploymentContainerDto = {
      idDealer: newDealer?.id ?? null,
      idCustomer: null,
      startDate: new Date(),
      location: [],
    };

    setData(newData);
    setIdLocation(newIdLocation);
    canSaveChange(newData, newIdLocation);
  };

  const customerChange = async (newCustomer: CustomerDto | undefined) => {
    const newData: KemaroRobotDeploymentContainerDto = {
      ...data!,
      idCustomer: newCustomer?.id ?? null,
    };

    const newIdLocation = newCustomer
      ? await getDefaultLocationFromCustomer(newCustomer.id)
      : idLocationDefault;

    setData(newData);
    setIdLocation(newIdLocation);

    canSaveChange(newData, newIdLocation);
  };

  const locationChange = (
    newLocation: CompanyStructureReducedDto | undefined
  ) => {
    const newIdLocation = newLocation?.id ?? idLocationDefault;

    setIdLocation(newIdLocation);
    canSaveChange(data!, newIdLocation);
  };

  const getDefaultLocationFromCustomer = async (idCustomer: string) => {
    let result: string = idLocationDefault;

    try {
      const root = await CompanyStructureRequests.getCustomerRoot(idCustomer);
      result = root.id;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setPermissions = () => {
    setCanSetDealer(
      PermissionsHelper.allow("KemaroRobots", "Edit", "Robot-Dealer-Assignment")
    );
    setCanSetCustomer(
      PermissionsHelper.allow(
        "KemaroRobots",
        "Edit",
        "Robot-Customer-Deployment"
      )
    );
    setCanSetLocation(
      PermissionsHelper.allow(
        "KemaroRobots",
        "Edit",
        "Robot-Customer-Deployment-Location"
      )
    );
  };

  const canSaveChange = (
    newData: KemaroRobotDeploymentContainerDto,
    newIdLocation: string
  ) => {
    const changeDealer = newData.idDealer !== dataOrigin.idDealer;
    const changeCustomer = newData.idCustomer !== dataOrigin.idCustomer;
    const changeLocation = newIdLocation !== dataOrigin.idLocation;
    const hasValueCustomerLocation =
      GeneralFunctions.isBlank(newData.idCustomer) ===
      GeneralFunctions.isBlank(newIdLocation);

    let save = false;
    if (changeDealer && (!changeCustomer || hasValueCustomerLocation))
      save = true;
    else if (changeCustomer && hasValueCustomerLocation) save = true;
    else if (!changeCustomer && changeLocation) save = true;

    setCanSave(save);
  };

  return (
    <Dialog
      className="application-modal"
      open={true}
      maxWidth="md"
      onClose={props.onClose}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <h3>{t("robotDeployment.modal.title")}</h3>
        </div>
        <div className="application-modal-body kemaro-robot-deployment-modal">
          {isLoading && (
            <div className="row mt-2 mb-5">
              <Loading isLoading={true} />
            </div>
          )}

          {!isLoading && data && (
            <div className="row">
              <div className="data-div input-label col-3">
                {t("robotDeployment.modal.dealer")}
              </div>
              <div className="data-div col-9">
                <DealerSearch
                  currentId={data.idDealer ?? " "}
                  disabled={!canSetDealer}
                  selectionChange={dealerChange}
                />
              </div>

              <div className="data-div input-label col-3">
                {t("robotDeployment.modal.customer")}
              </div>
              <div className="data-div col-9">
                <CustomerSearch
                  currentId={data.idCustomer}
                  dealerId={data.idDealer ?? undefined}
                  disabled={!canSetCustomer}
                  selectionChange={customerChange}
                />
              </div>
              <div className="data-div input-label col-3">
                {t("robotDeployment.modal.location")}
              </div>
              <div className="data-div col-9 ">
                <div className="row pt-2">
                  <div className="col-12">
                    <CompanyStructureSearch
                      disabled={!canSetLocation}
                      customerId={data.idCustomer ?? " "}
                      currentId={idLocation}
                      shortBreadcrumbs={true}
                      selectionChange={locationChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 pt-4">
                <h5>{t("robotDeployment.modal.subtitle")}</h5>
              </div>

              <div className="col-12 location-list">
                <TableGeneric
                  tableClassName=""
                  showHeader={true}
                  columns={kemaroRobotDeploymentModalColumnsDefinition}
                  items={data.location ?? []}
                  idProperty="id"
                />
              </div>

              {data.idCustomer && idOriginalCustomer !== data.idCustomer && (
                <div className="col-12 mt-2">
                  <Banner type="error">
                    <>{t("robotDeployment.modal.customerChange")}</>
                  </Banner>
                </div>
              )}

              <div className="col-12 text-end pt-4">
                <Button
                  className="modal-cancel-buttonv button-with-text me-3"
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                  disabled={!canSave}
                >
                  {t("common.buttons.save")}
                </Button>

                <Button
                  className="modal-cancel-buttonv button-with-text"
                  variant="outlined"
                  color="primary"
                  onClick={props.onClose}
                >
                  {t("common.buttons.cancel")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export interface KemaroRobotDeploymentModalProps {
  kemaroRobotId: string;
  onClose: () => void;
}

const idLocationDefault = " ";
