import { InputAdornment, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TooltipButton } from "./TooltipButton";

export const PasswordInput = ({
  isShowPassword,
  setIsShowPassword,
  value,
  setValue,
  minLenght,
  mustContainNumber = true,
  mustContainUpperCase = true,
  mustContainLowerCase = true,
  mustContainSpecialChars = true,
  children,
  chilContainerClass,
  validatePassword = true,
  autogenerateShow = true,
  autogenerateClick,
}: passwordInputProps) => {
  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const hasValue = value !== undefined && value !== null;

  const lengthRule = hasValue && value.length >= minLenght;
  const numberRule =
    !mustContainNumber || (hasValue && value.search(/(?=.*[0-9])/) >= 0);
  const lowerCaseRule =
    !mustContainLowerCase || (hasValue && value.search(/[a-z]/) >= 0);
  const upperCaseRule =
    !mustContainUpperCase || (hasValue && value.search(/[A-Z]/) >= 0);
  const specialCharsRule =
    !mustContainSpecialChars ||
    (hasValue && /[-"'`!#*&=$.%()+,/:?@^_{}~[\]\\]/.test(value));
  const forbiddenCharsRule =
    !hasValue && /[^"'`a-zA-Z0-9!#*&=$.%()+,/:?@^_{}~[\]\\]/.test(value);

  useEffect(() => {
    if (value === generateNewPassword) {
      handleGeneratePassword({
        setValue,
        minLenght,
        mustContainNumber,
        mustContainUpperCase,
        mustContainLowerCase,
        mustContainSpecialChars,
        validatePassword,
        autogenerateClick,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setValue,
    minLenght,
    mustContainNumber,
    mustContainUpperCase,
    mustContainLowerCase,
    mustContainSpecialChars,
    validatePassword,
    autogenerateClick,
  ]);

  return (
    <>
      <TextField
        type={isShowPassword ? "text" : "password"}
        label=""
        fullWidth
        variant="standard"
        margin="dense"
        hiddenLabel
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {autogenerateShow && (
                <TooltipButton
                  aria-label="random password"
                  tooltip={t("passwordInput.randomPassword")}
                  onClick={() =>
                    handleGeneratePassword({
                      setValue,
                      minLenght,
                      mustContainNumber,
                      mustContainUpperCase,
                      mustContainLowerCase,
                      mustContainSpecialChars,
                      validatePassword,
                      autogenerateClick,
                    })
                  }
                  edge="end"
                >
                  <AutoFixHighIcon />
                </TooltipButton>
              )}

              <TooltipButton
                aria-label="password visibility"
                tooltip={t("passwordInput.passwordVisibility")}
                onClick={handleClickShowPassword}
                edge="end"
              >
                {isShowPassword ? <Visibility /> : <VisibilityOff />}
              </TooltipButton>
            </InputAdornment>
          ),
        }}
      />

      {validatePassword && (
        <div className="row">
          <div className="col-12 col-lg-6 mt-2">
            <RequirementHelpText
              requirement={lengthRule}
              helpText={t("passwordInput.atLeastLong", {
                length: minLenght,
              })}
            />
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <RequirementHelpText
              requirement={numberRule}
              helpText={t("passwordInput.includeNumbers")}
            />
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <RequirementHelpText
              requirement={lowerCaseRule}
              helpText={t("passwordInput.includeLowerCase")}
            />
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <RequirementHelpText
              requirement={upperCaseRule}
              helpText={t("passwordInput.includeUpperCase")}
            />
          </div>
          <div className="col-12 col-lg-6 mt-2">
            <RequirementHelpText
              requirement={specialCharsRule}
              helpText={t("passwordInput.includeSpecialCharacters")}
            />
          </div>
          {forbiddenCharsRule && (
            <div className="col-12 col-lg-6 mt-2">
              <RequirementHelpText
                requirement={!forbiddenCharsRule}
                helpText={t("passwordInput.removeProhibited")}
              />
            </div>
          )}
          {children && (
            <div className={`col-12 col-lg-12 ${chilContainerClass ?? ""}`}>
              {children}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export interface passwordInputProps extends generatePasswordProps {
  isShowPassword: boolean;
  setIsShowPassword: (value: boolean) => void;
  value: string;
  children?: ReactElement | never[] | ReactElement[];
  chilContainerClass?: string;
  autogenerateShow?: boolean;
}
interface generatePasswordProps {
  setValue: (value: string) => void;
  minLenght: number;
  mustContainNumber?: boolean;
  mustContainUpperCase?: boolean;
  mustContainLowerCase?: boolean;
  mustContainSpecialChars?: boolean;
  validatePassword?: boolean;
  autogenerateClick?: (newPassword: string) => void;
}

const handleGeneratePassword = (props: generatePasswordProps) => {
  let allowChars = "";

  if (props.mustContainNumber) {
    allowChars += "0123456789";
  }

  if (props.mustContainLowerCase) {
    allowChars += "abcdefghijklmnopqrstuvwxyz";
  }

  if (props.mustContainUpperCase) {
    allowChars += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  }

  if (props.mustContainSpecialChars) {
    allowChars += ".,:_-!?()+$@/*=&";
  }

  const password = generatePassword(
    props.minLenght,
    allowChars,
    props.mustContainNumber!,
    props.mustContainLowerCase!,
    props.mustContainUpperCase!,
    props.mustContainSpecialChars!
  );

  props.setValue(password);

  if (props.autogenerateClick) {
    props.autogenerateClick(password);
  }
};

const RequirementHelpText = (props: requirementHelpTextProps) => (
  <Box
    margin={0}
    padding={0}
    fontSize="0.8rem"
    color={props.requirement ? "green" : "red"}
  >
    {props.requirement ? (
      <span>&#10003;&nbsp;</span>
    ) : (
      <span>&nbsp;&nbsp;&nbsp;</span>
    )}
    {props.helpText}
  </Box>
);

interface requirementHelpTextProps {
  requirement: boolean;
  helpText: string;
}

function generatePassword(
  length: number,
  allowChars: string,
  mustContainNumber: boolean,
  mustContainLowerCase: boolean,
  mustContainUpperCase: boolean,
  mustContainSpecialChars: boolean
) {
  let isOk = false;
  let result = "";
  while (!isOk) {
    result = "";
    for (let i = 0; i < length; i++) {
      result += allowChars[Math.floor(Math.random() * allowChars.length)];
    }

    const numberRule = !mustContainNumber || result.search(/(?=.*[0-9])/) >= 0;
    const lowerCaseRule = !mustContainLowerCase || result.search(/[a-z]/) >= 0;
    const upperCaseRule = !mustContainUpperCase || result.search(/[A-Z]/) >= 0;
    const specialCharsRule =
      !mustContainSpecialChars || /[.,:_\-!?()+$@/*=&]/.test(result);

    isOk = numberRule && lowerCaseRule && upperCaseRule && specialCharsRule;
  }

  return result;
}

export const generateNewPassword = "-generate-";
