import { useContext, useEffect, useState } from "react";
import { Alert, Button, Dialog, Grid, Stack, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import {
  ProcessBehaviour,
  ProcessLinkDto,
  UserCompleteDto,
  UserMigrateAccountDto,
} from "../../../../robotcloud-shared/resource-models";
import { Loading } from "../../components/Loading";
import { useTranslation } from "react-i18next";
import {
  tableGenericHandleKeyboardSubjectManager,
  ToastHelper,
} from "../../components/news";
import { ErrorHelper } from "../../uiHelpers/errors.helper";
import "./onBoardingAssistant.scss";
import { WorkflowsRequests } from "../../data/workflows.request";
import { ProcessesRequests } from "../../data/processes.request";
import { LinkWorkflow } from "./LinkWorkflow";
import {
  addPendingComplianceWorkflows,
  Process,
  Workflow,
} from "./onBoardingService";
import { FileWorkflow } from "./FileWorkflow";
import { CompanyContactWorkflow } from "./CompanyContactWorkflow";
import AuthorizationContext from "../auth/authorizationContext";
import { NotAllowedWorkflow } from "./NotAllowedWorkflow";
import useTheme from "@mui/material/styles/useTheme";
import { UserCompletionWorkflow } from "./UserCompletionWorkflow";
import { UsersRequests } from "../../data/users.requests";
import OnBoardingAssistantContext from "./OnBoardingAssistantContext";
import { UserMigrationWorkflow } from "./UserMigrationWorkflow";
import { processLinkIdKey } from "../mainPanel/MainPanel";
import { ManufacturerRequests } from "../../data/manufacturers.Requests";
import { modalOpenSubjectManager } from "../shared/modalSubjectManagers";

export const OnBoardingAssistant = (props: OnBoardingAssistantProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { me, setAllowedManufacturers, setCurrentManufacturer } =
    useContext(AuthorizationContext);
  const [language] = useState<string>(
    props.demoLanguage ?? me?.language?.toString() ?? "en"
  );
  const [currentBehaviour, setCurrentBehaviour] =
    useState<ProcessBehaviour>("compliance");
  const [, setComplianceProcesses] = useState<Process[]>([]);
  const [linkedProcesses, setLinkedProcesses] = useState<Process[]>([]);
  const [currentProcesses, setCurrentProcesses] = useState<Process[]>([]);
  const [currentProcess, setCurrentProcess] = useState<Process | undefined>();
  const [currentWorkflow, setCurrentWorkflow] = useState<
    Workflow | undefined
  >();
  const [accepted, setAccepted] = useState<boolean>(false);
  const [onReady, setOnReady] = useState<boolean>(false);

  const [newUser, setNewUser] = useState<UserCompleteDto | undefined>();
  const [originalUser, setOriginalUser] = useState<
    UserCompleteDto | undefined
  >();

  useEffect(() => {
    const complianceProcesses = props.processes.filter(
      (x) => x.behaviour === "compliance"
    );
    setComplianceProcesses(complianceProcesses);
    const linkedProcesses = props.processes.filter(
      (x) => x.behaviour === "link"
    );
    setLinkedProcesses(linkedProcesses);
    // Always start by the compliance processes if there are.
    setCurrentBehaviour(complianceProcesses.length > 0 ? "compliance" : "link");
    setCurrentProcesses(
      complianceProcesses.length > 0 ? complianceProcesses : linkedProcesses
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentProcesses.length > 0) {
      const newProcess = currentProcesses[0];
      setCurrentProcess(newProcess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProcesses]);

  const [onBoardingTitle, setOnBoardingTitle] = useState<string>(
    t("onBoarding.assistant.title")
  );
  useEffect(() => {
    if (currentProcess && currentProcess.workflows.length > 0) {
      if (currentProcess.behaviour === "link") {
        setOnBoardingTitle(
          `${t("onBoarding.assistant.title")} ${currentProcess.translatedTitle}`
        );
        addPendingComplianceWorkflows(currentProcess, language).then(() => {
          setCurrentWorkflow(currentProcess.workflows[0]);
        });
      } else {
        setCurrentWorkflow(currentProcess.workflows[0]);
        setOnBoardingTitle(t("onBoarding.assistant.title"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProcess]);

  useEffect(() => {
    if (currentWorkflow) {
      // If current process is linked, check the pending compliance processes and add its workflows
      if (currentProcess?.behaviour === "link") {
        addPendingComplianceWorkflows(currentProcess, language).then(
          (result) => {
            if (result) {
              // Only change the workflow if we did changes in the workflows list.
              const firstNotVisited = currentProcess.workflows.find(
                (x) => x.isVisited === false
              );
              if (firstNotVisited) {
                firstNotVisited.isVisited = true;
                setCurrentWorkflow(firstNotVisited);
              }
            } else {
              currentWorkflow.isVisited = true;
            }
          }
        );
      } else {
        currentWorkflow.isVisited = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkflow]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "CustomerCreateModal",
      handleKeyboard: props.openPopup,
    });

    modalOpenSubjectManager.setData(props.openPopup);
  }, [props.openPopup]);

  const handleContinue = async () => {
    if (currentWorkflow?.type === "userDataCompletion") {
      if (!(await modifyCompleteUser(originalUser, newUser))) return;
    }

    if (currentWorkflow?.type === "userAccountMigration") {
      if (!(await migrateAccount())) return;
    }

    if (props.demo) {
      goToNextWorkflow();
    } else {
      if (currentWorkflow) {
        // Also will mark the processlink as started by this user.
        const result = await WorkflowsRequests.completeWorkflow(
          currentWorkflow.id,
          accepted,
          props.processLink?.id
        );
        if (result.status === 200) {
          goToNextWorkflow();
        } else {
          ToastHelper.errors(ErrorHelper.process(result.data));
        }
      }
    }
  };

  const goToNextWorkflow = () => {
    if (currentProcesses && currentProcess && currentWorkflow) {
      const currentWorkflowIndex = currentProcess.workflows.findIndex(
        (x) => x.id === currentWorkflow.id
      );
      if (currentWorkflowIndex < currentProcess.workflows.length - 1) {
        setCurrentWorkflow(currentProcess.workflows[currentWorkflowIndex + 1]);
      } else {
        // Go to next process.
        goToNextProcess();
      }
    }
  };

  const goToNextProcess = () => {
    if (currentProcesses && currentProcess && currentWorkflow) {
      const currentProcessIndex = currentProcesses.findIndex(
        (x) => x.id === currentProcess.id
      );
      if (currentProcessIndex < currentProcesses.length - 1) {
        const newProcess = currentProcesses[currentProcessIndex + 1];
        setCurrentProcess(newProcess);
      } else {
        // Go to next behaviour.
        if (currentBehaviour === "compliance" && linkedProcesses.length > 0) {
          setCurrentBehaviour("link");
          setCurrentProcesses(linkedProcesses);
        } else {
          props.onClose && props.onClose();
        }
      }
    }
  };

  const createWorkflows = () => {
    if (currentBehaviour === "compliance") {
      // Show all processes at the same time.
      return currentProcesses.map((process: Process) => {
        return (
          <ul key={process.id}>
            {process.workflows.map((workflow: Workflow) => {
              return (
                <li
                  key={workflow.id}
                  className={`text-overflow-ellipsis workflow-label ${
                    workflow.id === currentWorkflow?.id
                      ? "current-workflow-label"
                      : workflow.isVisited
                      ? "workflow-visited"
                      : ""
                  }`}
                  style={
                    workflow.id === currentWorkflow?.id
                      ? {
                          color:
                            theme.palette.swarm?.onBoardingAssistant
                              ?.currentWorkflowLabel,
                          backgroundColor:
                            theme.palette.swarm?.onBoardingAssistant
                              ?.currentWorkflowLabelBackground,
                        }
                      : workflow.isVisited
                      ? {
                          color:
                            theme.palette.swarm?.onBoardingAssistant
                              ?.workflowVisited,
                        }
                      : {
                          color:
                            theme.palette.swarm?.onBoardingAssistant
                              ?.workflowLabel,
                        }
                  }
                >
                  <Tooltip
                    title={`${workflow.manufacturer} - ${workflow.translatedTitle}`}
                    arrow
                    followCursor
                  >
                    <span>{`${workflow.manufacturer} - ${workflow.translatedTitle}`}</span>
                  </Tooltip>
                </li>
              );
            })}
          </ul>
        );
      });
    } else {
      // Show only the current process that must be a linked one.
      if (currentProcess) {
        return (
          <>
            <ul>
              {currentProcess.workflows.map((workflow: Workflow) => {
                if (!workflow.isAddedByComplianceRequirements) {
                  return (
                    <li
                      key={workflow.id}
                      className={`workflow-label ${
                        considerCurrentWorkflowInLinkedProcess(workflow)
                          ? "current-workflow-label"
                          : workflow.isVisited
                          ? "workflow-visited"
                          : ""
                      }`}
                      style={
                        workflow.id === currentWorkflow?.id
                          ? {
                              color:
                                theme.palette.swarm?.onBoardingAssistant
                                  ?.currentWorkflowLabel,
                              backgroundColor:
                                theme.palette.swarm?.onBoardingAssistant
                                  ?.currentWorkflowLabelBackground,
                            }
                          : workflow.isVisited
                          ? {
                              color:
                                theme.palette.swarm?.onBoardingAssistant
                                  ?.workflowVisited,
                            }
                          : {
                              color:
                                theme.palette.swarm?.onBoardingAssistant
                                  ?.workflowLabel,
                            }
                      }
                    >
                      {`${workflow.manufacturer} - ${workflow.translatedTitle}`}
                    </li>
                  );
                } else {
                  return <></>;
                }
              })}
            </ul>
          </>
        );
      }
    }
  };

  const considerCurrentWorkflowInLinkedProcess = (workflow: Workflow) => {
    if (workflow.id === currentWorkflow?.id) return true;

    if (currentWorkflow?.isAddedByComplianceRequirements) {
      var firstNotVisitedNotAddedWorflow = currentProcess?.workflows.find(
        (x) => !x.isAddedByComplianceRequirements && !x.isVisited
      );
      if (workflow.id === firstNotVisitedNotAddedWorflow?.id) return true;
    }

    return false;
  };

  const canAbort = (): boolean => {
    if (currentProcess && currentWorkflow) {
      return (
        currentProcess.behaviour === "link" &&
        currentWorkflow.abortable &&
        currentProcess.processLinks.length === 1
      );
    } else return false;
  };

  const handleAbort = async () => {
    if (canAbort()) {
      if (props.demo) {
        goToNextProcess();
      } else {
        const result = await ProcessesRequests.abortProcessLink(
          currentProcess!.processLinks[0].id
        );
        if (result.status === 200) {
          goToNextProcess();
        } else {
          ToastHelper.errors(ErrorHelper.process(result.data));
        }
      }
    }
  };

  const onAccepting = (accept: boolean) => {
    setAccepted(accept);
    setOnReady(accept || (!!currentWorkflow && !currentWorkflow.required));
  };

  const createWorkflowComponent = (workflow: Workflow): JSX.Element => {
    if (workflow.isNotAllowedWorkflow) {
      return (
        <NotAllowedWorkflow workflow={workflow} initialLanguage={language} />
      );
    }

    if (workflow?.type === "link") {
      return (
        <LinkWorkflow
          onAccepting={onAccepting}
          workflow={workflow}
          initialLanguage={language}
        />
      );
    }

    if (workflow?.type === "file") {
      return (
        <FileWorkflow
          onAccepting={onAccepting}
          workflow={workflow}
          initialLanguage={language}
        />
      );
    }

    if (workflow?.type === "companyContactCreation") {
      return (
        <CompanyContactWorkflow
          onReady={setOnReady}
          workflow={workflow}
          initialLanguage={language}
          demo={props.demo}
        />
      );
    }

    if (workflow?.type === "userDataCompletion") {
      return (
        <UserCompletionWorkflow
          onReady={setOnReady}
          workflow={workflow}
          initialLanguage={language}
        />
      );
    }

    if (workflow?.type === "userAccountMigration") {
      return (
        <UserMigrationWorkflow
          onReady={setOnReady}
          workflow={workflow}
          initialLanguage={language}
        />
      );
    }

    return <span>No implemented component</span>;
  };

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "WorkflowTranslatedDataDialog",
      handleKeyboard: props.openPopup,
    });

    modalOpenSubjectManager.setData(props.openPopup);

    return () => {
      tableGenericHandleKeyboardSubjectManager.setData({
        caller: "WorkflowTranslatedDataDialog",
        handleKeyboard: false,
      });

      modalOpenSubjectManager.setData(false);
    };
  }, [props.openPopup]);

  const modifyCompleteUser = async (
    originalUser?: UserCompleteDto,
    newUser?: UserCompleteDto
  ): Promise<boolean> => {
    if (originalUser && newUser) {
      try {
        await UsersRequests.modifyCompleteMe(originalUser, newUser);
        ToastHelper.success(
          `${t("user.entityName")} ${t("common.modifiedSuccessfully")}`
        );
        return true;
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    }
    return false;
  };

  const migrateAccount = async (): Promise<boolean> => {
    try {
      const userMigrateAccountDto: UserMigrateAccountDto = {
        firstName: newUser?.firstName ?? "",
        lastName: newUser?.lastName ?? "",
        externalId: newUser?.externalId ?? "",
        email: newUser?.userName ?? "",
        windowsTimeZoneId: newUser?.windowsTimeZoneId ?? "",
      };
      await UsersRequests.migrateAccount(userMigrateAccountDto);
      localStorage.removeItem(processLinkIdKey);
      // update manufacturers after migration.
      const manufacturers = await ManufacturerRequests.getAllowed();
      if (manufacturers) {
        setAllowedManufacturers(manufacturers);
        setCurrentManufacturer(
          manufacturers.filter((x) => x.name.toLowerCase() === "kemaro")[0] ??
            manufacturers[0]
        );
      } else {
        setAllowedManufacturers([]);
        setCurrentManufacturer(undefined);
      }
      ToastHelper.success(
        `${t("user.entityName")} ${t("user.migratedSuccessfully")}`
      );
      return true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
    return false;
  };

  return (
    <OnBoardingAssistantContext.Provider
      value={{
        originalUser: undefined,
        setOriginalUser,
        newUser: undefined,
        setNewUser,
        processLink: props.processLink,
      }}
    >
      <Dialog
        id="onboarding-dialog"
        className="application-modal dialog"
        open={props.openPopup}
        maxWidth={false}
        onClose={() => {
          if (props.demo) handleContinue();
        }}
      >
        <div>
          <Stack>
            <Box
              className="application-modal-header d-flex"
              id="onboarding-header"
            >
              <div className="row col-12">
                <h3 className="col-7">{onBoardingTitle}</h3>
                {props.demo && (
                  <Alert className="col-5 mt-3 text-center" severity="success">
                    {t("onBoarding.assistant.demoAlert")}
                  </Alert>
                )}
              </div>
            </Box>
            {props.isWorking ? (
              <div className="py-4">
                <Loading isLoading={props.isWorking} />
              </div>
            ) : (
              <>
                <Box id="onboarding-body">
                  <Grid container className="onboarding-container">
                    <Grid
                      id="workflows-list"
                      item
                      sm={4}
                      sx={{
                        borderRight:
                          theme.palette.swarm?.onBoardingAssistant
                            ?.workflowsListBorderRight,
                      }}
                    >
                      {createWorkflows()}
                    </Grid>
                    <Grid item sm={8} className="onboarding-content">
                      {currentWorkflow &&
                        createWorkflowComponent(currentWorkflow)}
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className="application-modal-footer"
                  id="onboarding-footer"
                >
                  {canAbort() && (
                    <Button
                      variant="contained"
                      className="button-with-text"
                      color="primary"
                      onClick={handleAbort}
                    >
                      {t("common.buttons.abort")}
                    </Button>
                  )}

                  <Button
                    className="modal-cancel-button button-with-text"
                    variant="outlined"
                    color="primary"
                    onClick={handleContinue}
                    disabled={
                      (!accepted &&
                        currentWorkflow &&
                        currentWorkflow.required) ||
                      !onReady
                    }
                  >
                    {t("common.buttons.continue")}
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </div>
      </Dialog>
    </OnBoardingAssistantContext.Provider>
  );
};

export interface OnBoardingAssistantProps {
  openPopup: boolean;
  isWorking: boolean;
  processes: Process[];
  processLink: ProcessLinkDto | undefined;
  onClose?: () => void;
  demo?: boolean;
  demoLanguage?: string;
}
