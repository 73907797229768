import "./auth.scss";

import swarm_s_logo from "../../assets/swarm_s_logo.png";
import earlybyteBrand from "../../assets/brands/earlybyte.png";
import kemaroBrand from "../../assets/brands/kemaro.png";
import { useEffect, useState } from "react";
import { StorageManagerService } from "../../data/storageManager.service";
import { ForgotPasswordModal } from "./forgotPasswordModal";
import { useTranslation } from "react-i18next";
import { InteractionType } from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";
import { getAuthRequest } from "../../data/auth";
import { Loading } from "../../components/Loading";

export const Unauthenticated = () => {
  const { t } = useTranslation();

  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);

  useEffect(() => {
    StorageManagerService.setApiTokenContainer(undefined);
    StorageManagerService.setMe(undefined);
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { login } = useMsalAuthentication(
    InteractionType.Silent,
    getAuthRequest()
  );

  return (
    <div className="main-div">
      <table className="main-table">
        <tbody>
          <tr>
            <td className="td-first"></td>
          </tr>
          <tr>
            <td className="td-logo">
              <img src={swarm_s_logo} className="earlybyte" alt="Earlybyte" />
            </td>
          </tr>
          <tr>
            <td className="td-welcome">
              <p className="welcome-text">{t("loginScreen.title")} SWARM</p>
              <div>
                <>
                  {t("loginScreen.loginRedirection")}
                  <Loading isLoading={true} size={100} />
                </>
              </div>
            </td>
          </tr>
          <tr>
            <td className="td-separator-1"></td>
          </tr>
          <tr>
            <td className="td-brands">
              <table className="table-brands">
                <tbody>
                  <tr>
                    <td className="td-brand-left">
                      <img
                        src={earlybyteBrand}
                        className="logo-brand"
                        alt="Earlybyte"
                      />
                    </td>
                    <td className="td-brand-separtor"></td>
                    <td>
                      <img
                        src={kemaroBrand}
                        className="logo-brand"
                        alt="KEMARO"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td className="td-separator-2"></td>
          </tr>
          <tr>
            <td className="td-robot-cloud">
              <p>SWARM</p>
              <p>Made with ❤️ by Earlybyte</p>
            </td>
          </tr>

          <tr>
            <td className="td-last"></td>
          </tr>
        </tbody>
      </table>

      {openResetPasswordModal && (
        <ForgotPasswordModal
          openPopup={openResetPasswordModal}
          onCancel={() => setOpenResetPasswordModal(false)}
        />
      )}
    </div>
  );
};
