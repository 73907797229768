import { useState, useContext, useEffect } from "react";
import {
  KemaroRobotCleanedSurfaceChartDto,
  KemaroRobotCleaningDataTotalsDtoType,
} from "../../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../../components/news";
import { KemaroRobotRequests } from "../../../../data/kemaro/kemaroRobot.request";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";
import KemaroRobotContext from "../KemaroRobotContext";
import { ChartSize, defaultChartSize } from "../Models";
import { useWindowSize } from "../../../../hooks/useWindowSize";

export const useKemaroRobotCleanedSurfaceChart = (
  currentYear: number,
  currentCleaningDataTotal: KemaroRobotCleaningDataTotalsDtoType
) => {
  const [isFetching, setIsFetching] = useState(true);
  const [cleanedSurfaceChartDto, setCleanedSurfaceChartDto] = useState<
    KemaroRobotCleanedSurfaceChartDto | undefined
  >(undefined);
  const [chartSizeXl, setChartSizeXl] = useState<ChartSize>({
    ...defaultChartSize,
  });
  const [chartSizeXxl, setChartSizeXxl] = useState<ChartSize>({
    ...defaultChartSize,
  });
  const [width] = useWindowSize();
  const { currentItem } = useContext(KemaroRobotContext);

  useEffect(() => {
    if (!currentItem?.id || currentYear <= 0) return;
    getData(currentItem.id, currentYear, currentCleaningDataTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem, currentYear, currentCleaningDataTotal]);

  const getData = async (
    id: string,
    year: number,
    totalType: KemaroRobotCleaningDataTotalsDtoType
  ) => {
    setIsFetching(true);

    try {
      const data = await KemaroRobotRequests.cleanedSurfaceChart(
        id,
        year,
        totalType
      );
      setCleanedSurfaceChartDto(data);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetching(false);
  };

  useEffect(() => {
    const adjustChartSize = (baseWidth: number) => {
      const newWidth = (width * defaultChartSize.width) / baseWidth;
      return {
        ...defaultChartSize,
        width: newWidth,
        height: (newWidth * defaultChartSize.height) / defaultChartSize.width,
      };
    };

    setChartSizeXl(adjustChartSize(defaultChartSize.XlWidth));
    setChartSizeXxl(adjustChartSize(defaultChartSize.XxlWidth));
  }, [width]);

  return { isFetching, cleanedSurfaceChartDto, chartSizeXl, chartSizeXxl };
};
