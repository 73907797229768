import {
  BrowserAuthError,
  ClientConfigurationError,
  Configuration,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";

let authType: MsalConfigType = "AzureAdSingleTenant";

function getClientId(msalConfigType: MsalConfigType): string {
  switch (process.env.REACT_APP_STAGE) {
    case "CloudProd": {
      if (msalConfigType === "AzureAdSingleTenant") {
        return "e131a777-c021-401c-8759-b49c2f7b4575";
      } else return "90e4698e-4a30-49eb-80c6-9befaa4d8b6c";
    }
    case "CloudDemo": {
      if (msalConfigType === "AzureAdSingleTenant") {
        return "f66c3dd8-4e7c-4b54-8d9c-cd9ad1248e4e";
      } else return "f3dacda4-25b6-4ad1-9569-947de32f823e";
    }
    case "CloudDev": {
      if (msalConfigType === "AzureAdSingleTenant") {
        return "0ef39635-e8be-4209-9297-d8a8c471ae32";
      } else return "206513d5-315c-429c-91b6-3371d98f1da9";
    }
    default: {
      if (msalConfigType === "AzureAdSingleTenant")
        return "0ef39635-e8be-4209-9297-d8a8c471ae32";
      else return "5330b1b2-4d87-4942-b1ed-d23b8b91b4b4";
    }
  }
}

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1A_SWARM_SIGNUP_SIGNIN",
    forgotPassword: "B2C_1A_SWARM_PASSWORDRESET",
    editProfile: "B2C_1A_SWARM_PROFILEEDIT",
  },
  authorities: {
    azureMultitenantSignIn: {
      authority: "https://login.microsoftonline.com/organizations",
    },
    azureSingleTenantSignIn: {
      authority:
        "https://login.microsoftonline.com/eb039348-5786-4d62-b72e-545da449e309",
    },
    signUpSignIn: {
      authority:
        process.env.REACT_APP_STAGE === "CloudProd"
          ? "https://login.swarm.eb8.ch/eb8prodswarmciam.onmicrosoft.com/B2C_1A_SWARM_SIGNUP_SIGNIN"
          : process.env.REACT_APP_STAGE === "CloudDemo"
          ? "https://login.swarm.demo.eb8.ch/eb8demoswarmciam.onmicrosoft.com/B2C_1A_SWARM_SIGNUP_SIGNIN"
          : process.env.REACT_APP_STAGE === "CloudDev"
          ? "https://login.swarm.dev.eb8.ch/eb8devswarmciam.onmicrosoft.com/B2C_1A_SWARM_SIGNUP_SIGNIN"
          : "https://login.swarm.local.eb8.ch/eb8localswarmciam.onmicrosoft.com/B2C_1A_SWARM_SIGNUP_SIGNIN",
    },
    forgotPassword: {
      authority:
        process.env.REACT_APP_STAGE === "CloudProd"
          ? "https://login.swarm.eb8.ch/eb8prodswarmciam.onmicrosoft.com/B2C_1A_SWARM_PASSWORDRESET"
          : process.env.REACT_APP_STAGE === "CloudDemo"
          ? "https://login.swarm.demo.eb8.ch/eb8demoswarmciam.onmicrosoft.com/B2C_1A_SWARM_PASSWORDRESET"
          : process.env.REACT_APP_STAGE === "CloudDev"
          ? "https://login.swarm.dev.eb8.ch/eb8devswarmciam.onmicrosoft.com/B2C_1A_SWARM_PASSWORDRESET"
          : "https://login.swarm.local.eb8.ch/eb8localswarmciam.onmicrosoft.com/B2C_1A_SWARM_PASSWORDRESET",
    },
    editProfile: {
      authority:
        process.env.REACT_APP_STAGE === "CloudProd"
          ? "https://login.swarm.eb8.ch/eb8prodswarmciam.onmicrosoft.com/B2C_1A_SWARM_PROFILEEDIT"
          : process.env.REACT_APP_STAGE === "CloudDemo"
          ? "https://login.swarm.demo.eb8.ch/eb8demoswarmciam.onmicrosoft.com/B2C_1A_SWARM_PROFILEEDIT"
          : process.env.REACT_APP_STAGE === "CloudDev"
          ? "https://login.swarm.dev.eb8.ch/eb8devswarmciam.onmicrosoft.com/B2C_1A_SWARM_PROFILEEDIT"
          : "https://login.swarm.local.eb8.ch/eb8localswarmciam.onmicrosoft.com/B2C_1A_SWARM_PROFILEEDIT",
    },
  },
  authorityDomainAzureB2C:
    process.env.REACT_APP_STAGE === "CloudProd"
      ? "login.swarm.eb8.ch"
      : process.env.REACT_APP_STAGE === "CloudDemo"
      ? "login.swarm.demo.eb8.ch"
      : process.env.REACT_APP_STAGE === "CloudDev"
      ? "login.swarm.dev.eb8.ch"
      : "login.swarm.local.eb8.ch",
  authorityDomainAzureMultitenant: "login.microsoftonline.com",
};

const host = window.location.protocol + "//" + window.location.host;

const config: Configuration = {
  auth: {
    authority: b2cPolicies.authorities.azureMultitenantSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [
      b2cPolicies.authorityDomainAzureMultitenant,
      b2cPolicies.authorityDomainAzureB2C,
    ], // Mark your B2C tenant's domain as trusted.
    clientId: getClientId(authType),
    navigateToLoginRequestUrl: false,
    redirectUri: host,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export let authProvider: PublicClientApplication | undefined = undefined;

export const setAuthProvider = (newAuthProvider: PublicClientApplication) => {
  authProvider = newAuthProvider;
};

export const scopes = {
  default: ["34db06ea-45c0-4633-acf3-cbe35d40dfa8/.default"],
  openid: ["34db06ea-45c0-4633-acf3-cbe35d40dfa8/openid"],
  access_as_user: [
    process.env.REACT_APP_STAGE === "CloudProd"
      ? "https://eb8prodswarmciam.onmicrosoft.com/f601507c-723a-4b14-88da-78aa8ab41e40/access_as_user"
      : process.env.REACT_APP_STAGE === "CloudDemo"
      ? "https://eb8demoswarmciam.onmicrosoft.com/d0fe65a0-8d0f-475d-9dc1-8452b8ee23bb/access_as_user"
      : process.env.REACT_APP_STAGE === "CloudDev"
      ? "https://eb8devswarmciam.onmicrosoft.com/20cd8089-1e96-46f4-a926-738575a0b5d4/access_as_user"
      : "https://eb8localswarmciam.onmicrosoft.com/8f960c62-1b6f-4d8c-b2f2-c89239e4745d/access_as_user",
  ],
};

// Multitenant authrequest
export const singleTenantAuthRequest: RedirectRequest = {
  scopes: [`api://${getClientId(authType)}/access_as_user`],
};

// B2C authRequest
export const b2cAuthRequest: RedirectRequest = {
  scopes: [...scopes.access_as_user],
};

export const getAuthRequest = () => {
  if (authType === "AzureAdB2C") {
    return b2cAuthRequest;
  } else {
    return singleTenantAuthRequest;
  }
};

export const acquireAccessToken = async (
  msalInstance: PublicClientApplication
) => {
  const loginFlowRoutes = [
    "/",
    "/logout",
    "/new-login",
    "/forgot-password-confirm",
  ]; // login routes where we don't want to redirect to login (e.g. reset password)
  const currentRoute = new URL(window.location.href).pathname;
  const redirectAllowed = !loginFlowRoutes.includes(currentRoute);

  const activeAccount = msalInstance.getActiveAccount(); // returns a non-null value only if we have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  const request = {
    scopes: [...getAuthRequest().scopes],
    account: activeAccount || accounts[0],
  };

  if (!activeAccount && accounts.length === 0 && redirectAllowed) {
    authProvider?.acquireTokenRedirect(request);
  }

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch (error) {
    if (redirectAllowed) {
      authProvider?.acquireTokenRedirect(request);
    }
    if (
      error instanceof BrowserAuthError &&
      (error as any)?.errorCode === "monitor_window_timeout"
    ) {
      console.log("monitor_window_timeout", error);
      authProvider?.acquireTokenRedirect(request);
    } else if (
      error instanceof ClientConfigurationError &&
      (error as any)?.errorCode === "authority_mismatch"
    ) {
      console.log("authority_mismatch", error);
      await msalInstance.clearCache();
      authProvider?.acquireTokenRedirect(request);
    } else {
      console.log("acquireAccessToken error", error);
    }
  }
};

export type MsalConfigType = "AzureAdSingleTenant" | "AzureAdB2C";

export const getMsalConfig = (msalConfigType: MsalConfigType) => {
  authType = msalConfigType;
  if (msalConfigType === "AzureAdSingleTenant") {
    config.auth.authority =
      b2cPolicies.authorities.azureSingleTenantSignIn.authority;
  } else {
    config.auth.authority = b2cPolicies.authorities.signUpSignIn.authority;
  }
  config.auth.clientId = getClientId(msalConfigType);
  return config;
};
