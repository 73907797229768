import Box from "@mui/material/Box";
import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import { Loading } from "../Loading";
import { TooltipButton } from "./TooltipButton";
import { ReactElement } from "react";

export const TitleWithAction = ({
  title,
  titleContainerClassName,
  tooltip,
  isFetching = false,
  showButton = true,
  children,
  onClick,
}: titleWithActionProps) => {
  if (isFetching) {
    return <Loading isLoading={isFetching} size={30} />;
  }

  return (
    <Box className={titleContainerClassName}>
      <span>{title}</span>
      {showButton && children && children}
      {showButton && !children && (
        <TooltipButton onClick={onClick} tooltip={tooltip}>
          <PlaylistAdd color="primary" fontSize="medium" />
        </TooltipButton>
      )}
    </Box>
  );
};

export interface titleWithActionProps {
  title: string;
  titleContainerClassName?: string;
  tooltip: string;
  isFetching?: boolean;
  showButton?: boolean;
  children?: ReactElement | never[] | null;
  onClick?: () => void;
}
