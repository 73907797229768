import { RefObject, useContext, useEffect, useState } from "react";
import {
  CompanyStructureFilterCriteriaDto,
  CustomerFilterCriteriaDto,
  KemaroConfigurationDto,
  KemaroDashboardEconomyCostCalculationFilterCriteriaDto,
  KemaroMissionCompleteDto,
  KemaroMissionFilterCriteriaDto,
  KemaroMissionFilterCriteriaTimezone,
  KemaroMissionListDto,
  KemaroRobotFilterCriteriaDto,
} from "../../../robotcloud-shared/resource-models";
import {
  BadgeItem,
  ToastHelper,
  applicationBarFocusSubjectManager,
  tableGenericHandleKeyboardSubjectManager,
} from "../components/news";
import { KemaroMissionsRequests } from "../data/kemaro/kemaroMissions.request";
import { ErrorHelper } from "../uiHelpers/errors.helper";
import { GeneralFunctions } from "../views/generalFunctions";
import { OrderDto, PaginationDto, paginationDtoDefault } from "../data/common";
import AuthorizationContext from "../views/auth/authorizationContext";
import {
  filterToFilterCriteria,
  filterToBadgeItems,
  filterUpdate,
} from "../views/kemaro/missions/functions";
import { CompanyStructureRequests } from "../data/companyStructure.request";
import { CustomersRequests } from "../data/customers.request";
import { KemaroDashboardEconomyCostCalculationsRequests } from "../data/kemaro/kemaroDashboardEconomyCostCalculations.requests";
import { KemaroRobotRequests } from "../data/kemaro/kemaroRobot.request";
import { QueryParamHelper } from "../uiHelpers/queryParamHelper";
import {
  kemaroMissionsFilter,
  kemaroMissionsFilterEmpty,
} from "../views/kemaro/missions/KemaroMissionsFilterModal";
import { UsersRequests } from "../data/users.requests";
import { PermissionsHelper } from "../uiHelpers/permissionsHelper";
import { kemaroMissionsShowMapSubjectManager } from "../views/kemaro/missions/RxJS";
import { displayTimeList } from "../views/kemaro/missions/models";

export const useKemaroMissions = () => {
  const [lastSearch, setLastSearch] = useState("--");
  const [isProcessParameters, setIsProcessParameters] = useState(false);
  const [canTextSearch, setCanTextSearch] = useState(true);
  const [filterBadgeItems, setFilterBadgeItems] = useState<BadgeItem[]>([]);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [isFetchingItem, setIsFetchingItem] = useState(false);
  const [
    kemaroMissionsCsvZipModalIsWorking,
    setKemaroMissionsCsvZipModalIsWorking,
  ] = useState(false);

  const { me, configuration, kemaroMissionChange } =
    useContext(AuthorizationContext);
  const [lastKemaroMissionChange, setLastKemaroMissionChange] = useState<
    Date | undefined
  >(kemaroMissionChange);
  const [getByIdTimezoneToShow, setGetByIdTimezoneToShow] = useState<
    KemaroMissionFilterCriteriaTimezone | undefined
  >(undefined);
  const [items, setItems] = useState<KemaroMissionListDto[] | undefined>(
    undefined
  );
  const [ids, setIds] = useState<string[]>([]);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [currentItem, setCurrentItem] = useState<
    KemaroMissionCompleteDto | undefined
  >(undefined);
  const [filter, setFilter] = useState<kemaroMissionsFilter>(
    kemaroMissionsFilterEmpty
  );
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "startTime",
    order: "Desc",
  });
  const [timezonesToShow] = useState(displayTimeList);
  const [isTextSearchFocus, setIsTextSearchFocus] = useState(false);
  const [notifications, setNotifications] = useState<string[]>([]);
  const [isFirstRun, setIsFirstRun] = useState(true);

  const [canSendPdf, setCanSendPdf] = useState(false);
  const [canDownloadPdf, setCanDownloadPdf] = useState(false);
  const [currentTimezoneToShow, setCurrentTimezoneToShow] = useState(
    displayTimeList[0]
  );
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [openAddMission, setOpenAddMission] = useState(false);

  const [isKemaroMissionsCsvZipModalOpen, setIsKemaroMissionsCsvZipModalOpen] =
    useState(false);
  useEffect(() => {
    if (!isFirstRun || configuration === undefined) {
      return;
    }

    setIsFirstRun(true);

    const updateNotifications = async () => {
      try {
        const notificationConfigurations =
          await UsersRequests.getMeNotificationConfigurations();
        setNotifications(notificationConfigurations.map((x) => x.emailAddress));
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    updateNotifications();

    setCanSendPdf(
      PermissionsHelper.allow("KemaroMissions", "View", "PDF", "Send")
    );
    setCanDownloadPdf(
      PermissionsHelper.allow("KemaroMissions", "View", "PDF", "Download")
    );

    let toDate = new Date();
    toDate.setHours(0);
    toDate.setMinutes(0);
    toDate.setSeconds(0);

    let fromDate = new Date();
    fromDate.setHours(0);
    fromDate.setMinutes(0);
    fromDate.setSeconds(0);

    if (configuration) {
      fromDate.setMonth(
        fromDate.getMonth() -
          (configuration as KemaroConfigurationDto).missions.monthRange
      );
    }

    const qph = new QueryParamHelper(window.location.search);
    const tmpFrom = qph.getValue("from");
    if (tmpFrom) {
      fromDate = new Date(tmpFrom);
    }

    const tmpTo = qph.getValue("to");
    if (tmpTo) {
      toDate = new Date(tmpTo);
    }

    setFilter((prevState) => ({
      ...prevState,
      startDate: fromDate,
      stopDate: toDate,
    }));
  }, [configuration, isFirstRun]);

  useEffect(() => {
    const handleKeydown = (ev: KeyboardEvent) => {
      if (
        !currentItem ||
        isFilterDialogOpen ||
        openAddMission ||
        isKemaroMissionsCsvZipModalOpen ||
        isTextSearchFocus
      )
        return;

      if (ev.key === " " || ev.code === "Space") {
        ev.preventDefault();
        kemaroMissionsShowMapSubjectManager.setData();
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [
    currentItem,
    isFilterDialogOpen,
    openAddMission,
    isKemaroMissionsCsvZipModalOpen,
    isTextSearchFocus,
  ]);
  useEffect(() => {
    const processParameters = async (qph: QueryParamHelper) => {
      const id = qph.getValue("id");
      if (id) {
        setIds([id]);
        setCurrentId(id);
      }

      const idRobot = qph.getValue("idRobot");
      if (idRobot) {
        try {
          let robotFilter: KemaroRobotFilterCriteriaDto = {
            page: 1,
            maxItemCount: 1000,
            orderBy: "Asc",
            orderColumns: null,
            ids: null,
            serialNumbers: null,
            names: null,
            robotModelIds: null,
            dealerIds: null,
            customerIds: null,
            customerContactIds: null,
            kemaroDashboardEconomyCostCalculationIds: null,
            textToSearch: null,
            kemaroCompanyStructureIds: null,
            status: "All",
            errorStatus: "All",
            deploymentStatus: "All",
            tags: null,
          };

          if (idRobot) robotFilter.ids = [idRobot];

          const robot = await KemaroRobotRequests.getList(robotFilter);

          setFilter((prevState) => ({
            ...prevState,
            kemaroRobots: robot.items,
          }));
        } catch (error) {
          ToastHelper.errors(ErrorHelper.process(error));
        }
      }

      const idCustomer = qph.getValue("idCustomer");
      if (idCustomer) {
        try {
          const customerFilter: CustomerFilterCriteriaDto = {
            textToSearch: null,
            idKemaroDashboardEconomyCostCalculations: null,
            id: idCustomer,
            page: 1,
            maxItemCount: 10000,
            orderBy: null,
            orderColumns: null,
            idDealer: null,
          };

          const customers = await CustomersRequests.getList(customerFilter);

          setFilter((prevState) => ({
            ...prevState,
            customers: customers.items,
          }));
        } catch (error) {
          ToastHelper.errors(ErrorHelper.process(error));
        }
      }

      const idLocation = qph.getValue("idLocation");
      const includeChildren =
        qph.getValue("includeChildren")?.toLocaleLowerCase() === "true";
      if (idLocation) {
        try {
          const companyStructureFilter: CompanyStructureFilterCriteriaDto = {
            ids: [idLocation],
          };

          const companyStructures = await CompanyStructureRequests.getList(
            companyStructureFilter
          );

          setFilter((prevState) => ({
            ...prevState,
            companyStructures: companyStructures,
            includeChildrenNodes: includeChildren,
          }));
        } catch (error) {
          ToastHelper.errors(ErrorHelper.process(error));
        }
      }

      const idKemaroDashboardEconomyCostCalculation = qph.getValue(
        "idKemaroDashboardEconomyCostCalculation"
      );
      if (idKemaroDashboardEconomyCostCalculation) {
        let filterCriteria: KemaroDashboardEconomyCostCalculationFilterCriteriaDto =
          {
            page: 1,
            maxItemCount: 10000,
            orderBy: null,
            orderColumns: null,
            textToSearch: null,
            id: idKemaroDashboardEconomyCostCalculation,
            ids: null,
          };

        const calculations =
          await KemaroDashboardEconomyCostCalculationsRequests.getList(
            filterCriteria
          );

        setFilter((prevState) => ({
          ...prevState,
          kemaroDashboardEconomyCostCalculations: calculations.items,
        }));
      }

      setIsProcessParameters(false);
    };

    const qph = new QueryParamHelper(window.location.search);
    if (qph.any()) {
      setIsProcessParameters(true);
      processParameters(qph);
    }

    const applicationBarFocusSubscription = applicationBarFocusSubjectManager
      .getObservable()
      .subscribe((x) => setIsTextSearchFocus(x));

    return () => {
      applicationBarFocusSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if ((!filter.startDate && !filter.stopDate) || isProcessParameters) {
      return;
    }

    let allowTextSearch = false;
    if (configuration) {
      allowTextSearch =
        GeneralFunctions.monthDifference(filter.startDate, filter.stopDate) <=
        (configuration as KemaroConfigurationDto).missions.monthRange;
    }

    const conditions = filterToFilterCriteria(
      ids,
      filter,
      pagination,
      dataOrder,
      allowTextSearch,
      currentTimezoneToShow.value
    );

    const strConditions = JSON.stringify(conditions);

    if (lastSearch !== strConditions) {
      setLastSearch(strConditions);
      getList(conditions, currentItem, pagination, false);
      setFilterBadgeItems(filterToBadgeItems(filter, allowTextSearch));
      setCanTextSearch(allowTextSearch);
    } else if (
      !!kemaroMissionChange &&
      kemaroMissionChange !== lastKemaroMissionChange
    ) {
      setLastKemaroMissionChange(kemaroMissionChange);
      if (pagination.currentPage === 1) {
        getList(conditions, currentItem, pagination, false);
      }
    }
  }, [
    pagination,
    dataOrder,
    filter,
    lastSearch,
    configuration,
    isProcessParameters,
    kemaroMissionChange,
    lastKemaroMissionChange,
    ids,
    currentItem,
    currentTimezoneToShow,
  ]);

  const callGetList = async (selectFirstItem: boolean) => {
    const filterCriteria = JSON.parse(
      lastSearch
    ) as KemaroMissionFilterCriteriaDto;
    await getList(filterCriteria, currentItem, pagination, selectFirstItem);
  };

  const getList = async (
    conditions: KemaroMissionFilterCriteriaDto,
    ci: KemaroMissionCompleteDto | undefined,
    page: PaginationDto,
    selectFirstItem: boolean
  ) => {
    setIsFetchingList(true);

    try {
      const data = await KemaroMissionsRequests.getList(conditions);
      setItems(data.items);

      GeneralFunctions.ChangePagination(page, data, setPagination);

      if (data.items.length > 0) {
        if (selectFirstItem || !ci || !data.items.some((x) => x.id === ci.id)) {
          setCurrentId(data.items[0].id);
        }
      }
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingList(false);
  };

  const getKemaroMission = async (
    fetchingItem: boolean,
    ci: KemaroMissionCompleteDto | undefined,
    id: string,
    currentTimezoneToShow: KemaroMissionFilterCriteriaTimezone
  ) => {
    if (
      fetchingItem ||
      (ci?.id === id && currentTimezoneToShow === getByIdTimezoneToShow)
    )
      return;

    setIsFetchingItem(true);

    try {
      const item = await KemaroMissionsRequests.getById(
        id,
        currentTimezoneToShow
      );
      setCurrentItem(item);
      setCurrentId(item.id);
      setGetByIdTimezoneToShow(currentTimezoneToShow);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsFetchingItem(false);
  };
  const deleteEntity = async (item: KemaroMissionCompleteDto) => {
    let result = false;
    let selectFirstItem = false;

    try {
      await KemaroMissionsRequests.delete(item.id);
      const nextItem = GeneralFunctions.nextItemFromArray<KemaroMissionListDto>(
        items,
        item.id
      );

      setCurrentId(nextItem?.id ?? undefined);
      if (nextItem) {
        getKemaroMission(
          false,
          undefined,
          nextItem.id,
          currentTimezoneToShow.value
        );
      } else {
        selectFirstItem = true;
        setCurrentItem(undefined);
      }

      await callGetList(selectFirstItem);
      result = true;
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    return result;
  };
  useEffect(() => {
    if (currentId)
      getKemaroMission(
        isFetchingItem,
        currentItem,
        currentId,
        currentTimezoneToShow.value
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId, currentItem, isFetchingItem, currentTimezoneToShow]);

  const createHandle = async (id: string) => {
    setCurrentId(id);
    setCurrentItem(undefined);
    callGetList(false);
    setOpenAddMission(false);
  };

  const textSearchChange = (newTextToSearch: string) => {
    if (newTextToSearch === filter.textToSearch) return;
    setFilter({
      ...filter,
      textToSearch: newTextToSearch,
    });
    pageChange({
      ...pagination,
      currentPage: 1,
    });
  };

  const pageChange = (pagination: PaginationDto) => {
    setCurrentId(undefined);
    setCurrentItem(undefined);
    setPagination(pagination);
  };
  const handleShowAllmissionsOfRobot = async () => {
    const robotFilter: KemaroRobotFilterCriteriaDto = {
      page: 1,
      maxItemCount: 1000,
      orderBy: "Asc",
      orderColumns: null,
      ids: [currentItem!.robotId],
      serialNumbers: null,
      names: null,
      robotModelIds: null,
      dealerIds: null,
      customerIds: null,
      customerContactIds: null,
      kemaroDashboardEconomyCostCalculationIds: null,
      textToSearch: null,
      kemaroCompanyStructureIds: null,
      status: "All",
      errorStatus: "All",
      deploymentStatus: "All",
      tags: null,
    };

    const robot = await KemaroRobotRequests.getList(robotFilter);

    setFilter((prevState) => ({
      ...prevState,
      kemaroRobots: robot.items,
    }));

    setPagination((x) => ({
      ...x,
      currentPage: 1,
    }));
  };

  const handleDownloadCsv = async (withImages: boolean) => {
    setKemaroMissionsCsvZipModalIsWorking(true);

    let allowTextSearch = false;
    if (configuration) {
      allowTextSearch =
        GeneralFunctions.monthDifference(filter.startDate, filter.stopDate) <=
        (configuration as KemaroConfigurationDto).missions.monthRange;
    }

    try {
      const filterCriteria = filterToFilterCriteria(
        ids,
        filter,
        pagination,
        dataOrder,
        allowTextSearch,
        currentTimezoneToShow.value
      );

      const file = withImages
        ? await KemaroMissionsRequests.zip(filterCriteria)
        : await KemaroMissionsRequests.csv(filterCriteria);

      const decodedData = window.atob(file.content);

      const uInt8Array = new Uint8Array(decodedData.length);

      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }

      let blob = withImages
        ? new Blob([uInt8Array], { type: "application/zip" })
        : new Blob([uInt8Array], { type: "application/csv" });

      const linkElement = document.createElement("a");
      linkElement.download = file.name;
      linkElement.href = URL.createObjectURL(blob);
      linkElement.addEventListener("click", (e) => {
        setTimeout(() => URL.revokeObjectURL(linkElement.href), 30 * 1000);
      });
      linkElement.click();
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setKemaroMissionsCsvZipModalIsWorking(false);
    setIsKemaroMissionsCsvZipModalOpen(false);

    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroMissionsCsvZipModal",
      handleKeyboard: false,
    });
  };
  const badgeClick = (x: BadgeItem, refAapplicationBar: RefObject<any>) => {
    setCurrentId(undefined);
    setCurrentItem(undefined);
    setFilter({
      ...filterUpdate(filter, x, refAapplicationBar),
    });
  };
  const kemaroMissionFilterApply = (x: kemaroMissionsFilter) => {
    setCurrentId(undefined);
    setCurrentItem(undefined);
    setFilter({ ...x });
    setIsFilterDialogOpen(false);
    setPagination({
      ...pagination,
      currentPage: 1,
    });
  };
  return {
    // State variables
    me,
    currentId,
    currentItem,
    currentTimezoneToShow,
    dataOrder,
    filter,
    items,
    notifications,
    pagination,
    isFetchingList,
    isFetchingItem,
    isFilterDialogOpen,
    isKemaroMissionsCsvZipModalOpen,
    kemaroMissionsCsvZipModalIsWorking,
    openAddMission,

    // Set state functions
    setCurrentId,
    setCurrentTimezoneToShow,
    setDataOrder,
    setIsFilterDialogOpen,
    setIsKemaroMissionsCsvZipModalOpen,
    setOpenAddMission,

    // Configuration variables
    configuration,
    timezonesToShow,
    canTextSearch,
    canSendPdf,
    canDownloadPdf,

    // Handler functions
    badgeClick,
    deleteEntity,
    filterBadgeItems,
    handleShowAllmissionsOfRobot,
    handleDownloadCsv,
    kemaroMissionFilterApply,
    pageChange,
    textSearchChange,
    createHandle,
  };
};
