import { useEffect, useState } from "react";
import { KemaroDashboardEconomyRequests } from "../../../../../data/kemaro/KemaroDashboardEconomy.request";
import { QueryParamHelper } from "../../../../../uiHelpers/queryParamHelper";
import {
  DashboardCustomerDto,
  DashboardEconomyYearFilterDto,
  DashboardWeekDto,
  KemaroCompanyStructureDto,
} from "../../../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../../../components/news";
import { ErrorHelper } from "../../../../../uiHelpers/errors.helper";
import { KemaroCompanyStructureRequests } from "../../../../../data/kemaro/KemaroCompanyStructure.request";

export const useKemaroDashboardEconomyFilterData = () => {
  const [firstCompanyStructurefromQuery, setFirstCompanyStructurefromQuery] =
    useState(false);
  const [firstYearfromQuery, setFirstYearfromQuery] = useState(false);
  const [firstWeekfromQuery, setFirstWeekfromQuery] = useState(false);

  const [isGettingCustomers, setIsGettingCustomers] = useState(true);
  const [customers, setCustomers] = useState<DashboardCustomerDto[]>([]);
  const [customer, setCustomer] = useState<DashboardCustomerDto | undefined>(
    undefined
  );

  const [companyStructures, setCompanyStructures] = useState<
    KemaroCompanyStructureDto[]
  >([]);
  const [companyStructure, setCompanyStructure] = useState<
    KemaroCompanyStructureDto | undefined
  >(undefined);
  const [companyStructureChildren, setCompanyStructureChildren] =
    useState(true);

  const [calculationId, setCalculationId] = useState("");

  const [years, setYears] = useState<number[]>([]);
  const [year, setYear] = useState<number>(0);

  const [weeks, setWeeks] = useState<DashboardWeekDto[]>([]);
  const [week, setWeek] = useState<DashboardWeekDto | undefined>(undefined);

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWeeks(
      customer?.id,
      calculationId,
      companyStructure?.id,
      companyStructureChildren,
      year,
      firstWeekfromQuery
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customer,
    calculationId,
    companyStructure,
    companyStructureChildren,
    year,
    firstWeekfromQuery,
  ]);

  const getCustomers = async () => {
    setIsGettingCustomers(true);

    try {
      let result = await KemaroDashboardEconomyRequests.getCustomers();
      setCustomers(result);

      const qph = new QueryParamHelper(window.location.search);
      const customerId = qph.getValue("customerId") ?? null;
      const customer =
        customerId === null
          ? result.filter((x) => x.hasData)[0]
          : result.find((x) => x.id === customerId);

      customerChange(customer);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsGettingCustomers(false);
  };

  const getCompanyStructures = async (
    customer: DashboardCustomerDto | undefined,
    isFirstCompanyStructurefromQuery: boolean
  ) => {
    if (!customer?.id) {
      setCompanyStructures([]);
      setCompanyStructure(undefined);
      return;
    }

    try {
      var result = await KemaroCompanyStructureRequests.getStructureList(
        customer.id
      );

      setCompanyStructures(result);
      let cs: KemaroCompanyStructureDto | undefined = undefined;

      if (isFirstCompanyStructurefromQuery) {
        const qph = new QueryParamHelper(window.location.search);
        const companyStructureId = qph.getValue("companyStructureId") ?? null;

        cs =
          companyStructureId === null
            ? undefined
            : result.find((x) => x.id === companyStructureId);

        setCompanyStructureChildren(
          (qph.getValue("incchild")?.toUpperCase() ?? "Y") === "Y"
        );
      }

      companyStructureChange(cs ?? result[0]);
      setFirstCompanyStructurefromQuery(true);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const getYears = async (
    idCustomer: string | undefined,
    idCalculation: string,
    idCompanyStructure: string | undefined,
    includeCompanyStructureChildren: boolean,
    isFirstYearfromQuery: boolean
  ) => {
    if (!idCustomer || !idCalculation || !idCompanyStructure) {
      setYears([]);
      setYear(0);
      return;
    }

    try {
      const filter: DashboardEconomyYearFilterDto = {
        idCompanyStructure: idCompanyStructure,
        includeChildren: includeCompanyStructureChildren,
      };

      const result = await KemaroDashboardEconomyRequests.getYears(
        idCustomer,
        idCalculation,
        filter
      );

      setYears(result);

      let selectYear: number | undefined = undefined;

      if (isFirstYearfromQuery) {
        const qph = new QueryParamHelper(window.location.search);
        const yearQp = qph.getValue("year");

        if (yearQp) selectYear = parseInt(yearQp, 10);
      }

      yearChange(selectYear ?? result[0] ?? 0);
      setFirstYearfromQuery(true);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const getWeeks = async (
    idCustomer: string | undefined,
    idCalculation: string,
    idCompanyStructure: string | undefined,
    includeCompanyStructureChildren: boolean,
    year: number,
    isFirstWeekfromQuery: boolean
  ) => {
    if (!idCustomer || !idCalculation || year === 0 || !idCompanyStructure) {
      setWeeks([]);
      setWeek(undefined);
      return;
    }

    try {
      const filter: DashboardEconomyYearFilterDto = {
        idCompanyStructure: idCompanyStructure,
        includeChildren: includeCompanyStructureChildren,
      };

      const result = await KemaroDashboardEconomyRequests.getWeeks(
        idCustomer,
        idCalculation,
        year,
        filter
      );

      setWeeks(result);

      let w: DashboardWeekDto | undefined = undefined;

      if (isFirstWeekfromQuery) {
        const qph = new QueryParamHelper(window.location.search);
        const weekQp = qph.getValue("week") ?? null;

        w =
          weekQp === null
            ? undefined
            : result.find((x) => x.week === parseInt(weekQp, 10));
      }

      weekChange(w ?? result[0]);
      setFirstWeekfromQuery(true);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }
  };

  const customerChange = async (customer: DashboardCustomerDto | undefined) => {
    setCustomer(customer);
    await getCompanyStructures(customer, firstCompanyStructurefromQuery);
  };

  const companyStructureChange = (
    companyStructure: KemaroCompanyStructureDto | undefined
  ) => {
    const idCalculation =
      companyStructure?.idKemaroDashboardEconomyCostCalculation ??
      companyStructure?.idKemaroDashboardEconomyCostCalculationInherited ??
      "";

    setCompanyStructure(companyStructure);
    setCalculationId(idCalculation);

    getYears(
      companyStructure?.idCustomer,
      idCalculation,
      companyStructure?.id,
      companyStructureChildren,
      firstYearfromQuery
    );
  };

  const companyStructureChildrenChange = (newValue: boolean) => {
    setCompanyStructureChildren(newValue);

    getYears(
      companyStructure?.idCustomer,
      calculationId,
      companyStructure?.id,
      newValue,
      firstYearfromQuery
    );
  };

  const yearChange = (newValue: number) => {
    setYear(newValue);
  };

  const weekChange = (newValue: DashboardWeekDto | undefined) => {
    setWeek(newValue);
  };

  return {
    isGettingCustomers,
    customer,
    customers,
    companyStructures,
    companyStructure,
    companyStructureChildren,
    calculationId,
    years,
    year,
    weeks,
    week,
    customerChange,
    companyStructureChange,
    companyStructureChildrenChange,
    yearChange,
    weekChange,
  };
};
