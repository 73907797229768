import { useState, useEffect } from "react";
import { KemaroRobotDeploymentContainerDto } from "../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../components/news";
import { kemaroRobotDeploymentGetFromKemaroRobot } from "../data/kemaro/kemaroRobotDeployment.request";
import { ErrorHelper } from "../uiHelpers/errors.helper";

export const useKemaroRobotDeploymentData = (kemaroRobotId: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<
    KemaroRobotDeploymentContainerDto | undefined
  >(undefined);
  const [idOriginalCustomer, setIdOriginalCustomer] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (!kemaroRobotId) return;
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kemaroRobotId]);

  const getData = async () => {
    setIsLoading(true);

    try {
      const deploymentContainer = await kemaroRobotDeploymentGetFromKemaroRobot(
        kemaroRobotId
      );
      setData(deploymentContainer);
      setIdOriginalCustomer(deploymentContainer.idCustomer ?? undefined);
    } catch (error) {
      ToastHelper.errors(ErrorHelper.process(error));
    }

    setIsLoading(false);
  };

  return { data, setData, idOriginalCustomer, isLoading, setIsLoading };
};
