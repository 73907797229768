import { PlayCircleOutline } from "@mui/icons-material";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  WorkflowFilterCriteriaDto,
  WorkflowReducedDto,
  WorkflowTarget,
} from "../../../../../robotcloud-shared/resource-models";
import {
  CrudItemsPanel,
  Pagination,
  TableGeneric,
  ToastHelper,
  TooltipButton,
} from "../../../components/news";
import { TableGenericColumn } from "../../../components/news/TableGeneric/models";
import {
  PaginationDto,
  paginationDtoDefault,
  OrderDto,
} from "../../../data/common";
import { WorkflowsRequests } from "../../../data/workflows.request";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import AuthorizationContext from "../../auth/authorizationContext";
import { GeneralFunctions } from "../../generalFunctions";
import { OnBoardingAssistant } from "../OnBoardingAssistant";
import OnBoardingContext from "../OnBoardingContext";
import { Process, getProcessFromCompleteDto } from "../onBoardingService";
import { WorkflowDialog } from "./WorkflowDialog";

export const WorkflowPanel = ({
  fetchEvent,
  target,
}: WorkflowPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const { me } = useContext(AuthorizationContext);
  const {
    currentSelectedItem,
    setCurrentSelectedItem,
    refreshItems,
    process,
    manufacturerName,
    currentTarget,
    setCurrentTarget,
  } = useContext(OnBoardingContext);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [items, setItems] = useState<WorkflowReducedDto[] | undefined>(
    undefined
  );
  const [currentItem, setCurrentItem] = useState<
    WorkflowReducedDto | undefined
  >(undefined);
  const [pagination, setPagination] = useState<PaginationDto>({
    ...paginationDtoDefault,
    maxItemCount: 5,
  });
  const [dataOrder, setDataOrder] = useState<OrderDto>({
    orderColumns: "order",
    order: "Asc",
  });
  const [lastSearch, setLastSearch] = useState("--");
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDemo, setOpenDemo] = useState(false);

  const columnsDefinition: TableGenericColumn[] = [
    {
      title: t("onBoarding.workflow.order"),
      property: "order",
      cellTemplate: (item: WorkflowReducedDto) => (
        <span className={`${item.enabled ? "" : "text-disabled"}`}>
          {item.order}
        </span>
      ),
    },
    {
      title: t("onBoarding.workflow.name"),
      property: "name",
      itemClassName: "col-description text-overflow-ellipsis",
      cellTemplate: (item: WorkflowReducedDto) => (
        <span className={`${item.enabled ? "" : "text-disabled"}`}>
          {item.name}
        </span>
      ),
    },
    {
      title: t("onBoarding.workflow.type"),
      property: "type",
      cellTemplate: (item: WorkflowReducedDto) => (
        <span className={`${item.enabled ? "" : "text-disabled"}`}>
          {t(`onBoarding.workflow.workflowTypes.${item.type}` as const)}
        </span>
      ),
    },
    {
      title: t("onBoarding.workflow.validFrom"),
      property: "validFrom",
      cellTemplate: (item: WorkflowReducedDto) => (
        <span className={`${item.enabled ? "" : "text-disabled"}`}>
          {GeneralFunctions.dateToDateString(item.validFrom)}
        </span>
      ),
    },
  ];

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const getList = async (filterCriteria: WorkflowFilterCriteriaDto) => {
      setIsFetchingList(true);
      try {
        const data = await WorkflowsRequests.getList(filterCriteria);
        setItems(data.items);

        if (data.items.length > 0) {
          // Initially assign the item only in the workflow panel with priority.
          if (!currentItem && currentTarget === target) {
            setCurrentItem(data.items[0]);
          }

          // Manage maintenance update states
          if (currentItem) {
            if (currentSelectedItem) {
              // refresh current item.
              var currentRefreshed = data.items.find(
                (x) => x.id === currentSelectedItem.id
              );
              if (currentRefreshed) {
                //edited current item.
                setCurrentSelectedItem(currentRefreshed);
                setCurrentItem(currentRefreshed);
              } else {
                // added an item.
                setCurrentSelectedItem(currentSelectedItem);
                setCurrentItem(currentSelectedItem);
              }
            } else {
              // deleted current item, set the first one
              setCurrentSelectedItem(data.items[0]);
              setCurrentItem(data.items[0]);
            }
          }
        }

        if (
          pagination.currentPage !== data.currentPage ||
          pagination.totalItems !== data.totalItems
        ) {
          const newPagination = {
            ...pagination,
            currentPage: data.currentPage,
            totalItems: data.totalItems,
          };

          setPagination(newPagination);
        }
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }

      setIsFetchingList(false);
    };

    //Apply copy link id or manufacturer.
    const id = searchParams.get("id");
    const filterCriteria: WorkflowFilterCriteriaDto = {
      page: pagination.currentPage,
      maxItemCount: pagination.maxItemCount,
      orderBy: dataOrder.order,
      orderColumns: dataOrder.orderColumns,
      id: id,
      idProcess: null,
      manufacturerIdComplianceProcessOnly: null,
      target: target ?? null,
      enabled: null,
    };
    if (!id) {
      filterCriteria.manufacturerIdComplianceProcessOnly =
        me?.currentManufacturerId ?? null;
    }

    const strFilterCriteria = JSON.stringify(filterCriteria);
    if (lastSearch === strFilterCriteria) {
      return;
    }
    if (!id && !me?.currentManufacturerId) {
      return;
    }

    setLastSearch(strFilterCriteria);
    getList(filterCriteria);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination,
    dataOrder,
    lastSearch,
    currentItem,
    target,
    me,
    currentSelectedItem,
  ]);

  useEffect(() => {
    if (lastSearch !== "--") setLastSearch("--");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchEvent]);

  const itemClick = (item: WorkflowReducedDto) => {
    setCurrentItem(item);
    setCurrentSelectedItem(item);
  };

  const getTitle = (): string => {
    if (target) {
      if (target === "company") {
        return t("onBoarding.workflowCompanyPanelTitle");
      } else {
        return t("onBoarding.workflowUserPanelTitle");
      }
    } else {
      return t("onBoarding.workflow.entityNamePlural");
    }
  };

  const [demoProcess, setDemoProcess] = useState<Process[]>([]);
  const getDemoProcess = async (): Promise<Process[]> => {
    if (!process) return [];

    const id = searchParams.get("id");
    const filterCriteria: WorkflowFilterCriteriaDto = {
      page: 1,
      maxItemCount: 9999,
      orderBy: "Asc",
      orderColumns: "order",
      id: id,
      idProcess: null,
      manufacturerIdComplianceProcessOnly: null,
      target: target ?? null,
      enabled: true,
    };
    if (!id) {
      filterCriteria.manufacturerIdComplianceProcessOnly =
        me?.currentManufacturerId ?? null;
    }

    filterCriteria.maxItemCount = 9999;
    filterCriteria.page = 1;
    const data = await WorkflowsRequests.getListCompleteWokflows(
      filterCriteria
    );
    if (data.totalItems > 0) {
      return [
        getProcessFromCompleteDto(
          process,
          data.items,
          manufacturerName,
          me?.language ?? "en"
        ),
      ];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (currentTarget === target && !currentItem && items && items.length > 0) {
      setCurrentItem(items[0]);
    }
  }, [currentItem, currentTarget, items, target]);

  return (
    <>
      <CrudItemsPanel
        title={getTitle()}
        containerClassName="on-boarding-list-items"
        isFetching={isFetchingList}
        items={items}
        createToolTip={t("onBoarding.workflow.create.tooltip")}
        canCreate={true}
        onCreateClick={() => {
          setOpenCreateDialog(true);
        }}
        onClick={() => setCurrentTarget(target)}
        titleRightSide={
          <>
            <TooltipButton
              tooltip={t("onBoarding.demo") ?? ""}
              onClick={async () => {
                const demoProcess = await getDemoProcess();
                setDemoProcess(demoProcess);
                setOpenDemo(true);
              }}
            >
              <PlayCircleOutline color="primary" />
            </TooltipButton>
          </>
        }
      >
        <TableGeneric
          tableClassName="mt-4"
          columns={columnsDefinition}
          items={items ?? []}
          idProperty="id"
          headerClick={(header, itemsOrder) => setDataOrder(itemsOrder)}
          selectionChanged={itemClick}
          itemsOrder={dataOrder}
          selectId={currentTarget === target ? currentItem?.id : -1}
          captureKeyboard={currentTarget === target}
        />
      </CrudItemsPanel>

      <Pagination
        containerClassName="on-boarding-list-pager"
        pagination={pagination}
        isFetching={false}
        onChange={(x) => {
          setCurrentItem(undefined);
          setPagination(x);
        }}
      />

      {openCreateDialog && process && (
        <WorkflowDialog
          target={target}
          openPopup={openCreateDialog}
          onSaved={(workflow?: WorkflowReducedDto) => {
            workflow && setCurrentSelectedItem(workflow);
            setOpenCreateDialog(false);
            refreshItems(target);
          }}
          onCancel={() => setOpenCreateDialog(false)}
        />
      )}
      {openDemo && demoProcess.length > 0 && (
        <OnBoardingAssistant
          openPopup={openDemo}
          processLink={undefined}
          isWorking={false}
          demo={true}
          processes={demoProcess}
          onClose={() => setOpenDemo(false)}
        />
      )}
    </>
  );
};

export interface WorkflowPanelProps {
  fetchEvent: boolean;
  target: WorkflowTarget;
}
