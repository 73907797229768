import { ManufacturerCompleteDto } from "../../../../robotcloud-shared/resource-models";
import { useEffect, useState } from "react";
import { YesNoOkDialog } from "../../components/news/YesNoOkDialog";
import { ViewHeader } from "../../components/news/ViewHeader";
import { ManufacturerEditModal } from "./ManufacturerEditModal";
import { PermissionsHelper } from "../../uiHelpers/permissionsHelper";
import { useTranslation } from "react-i18next";

export const ManufacturerViewTitle = (props: manufacturerViewTitleProps) => {
  const { t } = useTranslation();

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [canModify, setCanModify] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    setCanModify(
      !PermissionsHelper.allow("Manufacturers", "Edit", "NO|NAME") &&
        props.canEdit
    );

    setCanDelete(
      PermissionsHelper.allow("Manufacturers", "Delete") && props.canEdit
    );
  }, [props]);

  const handleSave = (item: ManufacturerCompleteDto) => {
    props.onEdit(item);
    setOpenEditPopup(false);
  };

  const handleDeleteYes = () => {
    setIsWorking(true);
    props.onDelete(props.item);
    setOpenDeletePopup(false);
    setIsWorking(false);
  };

  return (
    <>
      <ViewHeader
        title={props.item.name}
        copyLinkId={props.item.id}
        canEdit={canModify}
        canDelete={canDelete}
        onEdit={() => setOpenEditPopup(true)}
        onDelete={() => setOpenDeletePopup(true)}
        children={null}
      />

      {openEditPopup && (
        <ManufacturerEditModal
          openPopup={openEditPopup}
          item={props.item}
          onSave={handleSave}
          onCancel={() => setOpenEditPopup(false)}
        />
      )}

      <YesNoOkDialog
        open={openDeletePopup}
        size="md"
        type="yesNo"
        title={t("manufacturer.delete.title")}
        message={`${t("manufacturer.delete.warning")} ${props.item.name}?`}
        onYes={handleDeleteYes}
        onNo={() => setOpenDeletePopup(false)}
        isWorking={isWorking}
      />
    </>
  );
};

export interface manufacturerViewTitleProps {
  item: ManufacturerCompleteDto;
  canEdit: boolean;
  onEdit: (item: ManufacturerCompleteDto) => void;
  onDelete: (item: ManufacturerCompleteDto) => void;
}
