import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

export const NumericInput = ({
  value,
  maxValue,
  minValue,
  ret,
  decimalPositions = 0,
}: numericInputProps) => {
  const [filedValue, setFieldValue] = useState(value?.toString());

  useEffect(() => {
    setFieldValue(value?.toString());
  }, [value]);

  const valueChange = (value: string) => {
    if (!separatorList[separatorType].regex.test(value)) {
      setFieldValue("");
      ret(0);
      return;
    }

    setFieldValue(value);

    let nValue =
      separatorType === "no" ? parseInt(value, 10) : parseFloat(value);

    if (minValue && nValue < minValue) {
      nValue = minValue;
      setFieldValue(nValue.toString());
    }

    if (maxValue && nValue > maxValue) {
      nValue = maxValue;
      setFieldValue(nValue.toString());
    }

    ret(nValue);
  };

  const separatorType = decimalPositions > 0 ? "period" : "no";

  return (
    <TextField
      label=""
      fullWidth
      multiline
      variant="standard"
      margin="dense"
      className="w-100"
      hiddenLabel
      value={filedValue}
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      onChange={({ target: { value } }) => valueChange(value)}
    />
  );
};

export interface numericInputProps {
  value?: number;
  maxValue?: number;
  minValue?: number;
  ret: (newValue: number) => void;
  decimalPositions?: number;
}

const separatorList = {
  no: {
    name: "comma",
    regex: /^\d+$/,
  },
  comma: {
    name: "comma",
    regex: /^\d+(,\d{0,2})?$/,
  },
  period: {
    name: "period",
    regex: /^\d+(\.\d{0,2})?$/,
  },
};
