import { theme as _default } from "./presets/default";
import { theme as kemaro } from "./presets/kemaro";
//import { theme as cleanfix } from "./presets/cleanfix";
import { Palette, PaletteColor } from "@mui/material/styles";
import { DeepPartial } from "./utility/types";

interface SwarmPaletteColor extends PaletteColor {
  name?: string; // palette name

  // colors from *.scss files

  app?: {
    header?: string;
    headerBackground?: string;
    link?: string;
    warningIcon?: string;
  };

  applicationBar?: {
    container?: string;
    containerBackground?: string;
    button?: string;
    searchInput?: string;
    searchInputContainerBackground?: string;
    searchInputBackground?: string;
    warningBoder?: string;
    warningBackground?: string;
  };

  auth?: {
    loginButton?: string;
    loginButtonBackground?: string;
    loginButtonHover?: string;
    loginButtonHoverBackground?: string;
    warning?: string;
    warningBackground?: string;
    warningBorder?: string;
    warningIcon?: string;
  };

  badgesPresenter?: {
    buttonRemoveBackground?: string;
    roundedPill?: string;
    roundedPillBorder?: string;
    roundedPillHoverBackground?: string;
    roundedPillRobot?: string;
    roundedPillRobotBorder?: string;
    roundedPillRobotHoverBackground?: string;
    roundedPillSerial?: string;
    roundedPillSerialBorder?: string;
    roundedPillSerialHoverBackground?: string;
    roundedPillName?: string;
    roundedPillNameBorder?: string;
    roundedPillNameHoverBackground?: string;
    roundedPillCompany?: string;
    roundedPillCompanyBorder?: string;
    roundedPillCompanyHoverBackground?: string;
    roundedPillModel?: string;
    roundedPillModelBorder?: string;
    roundedPillModelHoverBackground?: string;
    roundedPillCustomer?: string;
    roundedPillCustomerBorder?: string;
    roundedPillCustomerHoverBackground?: string;
    roundedPillDealer?: string;
    roundedPillDealerBorder?: string;
    roundedPillDealerHoverBackground?: string;
    roundedPillContact?: string;
    roundedPillContactBorder?: string;
    roundedPillContactHoverBackground?: string;
    roundedPillManufacturer?: string;
    roundedPillManufacturerBorder?: string;
    roundedPillManufacturerHoverBackground?: string;
    roundedPillMission?: string;
    roundedPillMissionBorder?: string;
    roundedPillMissionHoverBackground?: string;
    roundedPillStatus?: string;
    roundedPillStatusBorder?: string;
    roundedPillStatusHoverBackground?: string;
    roundedPillCountry?: string;
    roundedPillCountryBorder?: string;
    roundedPillCountryHoverBackground?: string;
    roundedPillDashboard?: string;
    roundedPillDashboardBorder?: string;
    roundedPillDashboardHoverBackground?: string;
    roundedPillVarious?: string;
    roundedPillVariousBorder?: string;
    roundedPillVariousHoverBackground?: string;
    roundedPillSearch?: string;
    roundedPillSearchBorder?: string;
    roundedPillSearchHoverBackground?: string;
    roundedPillError?: string;
    roundedPillErrorBorder?: string;
    roundedPillErrorHoverBackground?: string;
    roundedPillTag?: string;
    roundedPillTagBorder?: string;
    roundedPillTagHoverBackground?: string;
    roundedPillStopReason?: string;
    roundedPillStopReasonBorder?: string;
    roundedPillStopReasonHoverBackground?: string;
    roundedPillStartReason?: string;
    roundedPillStartReasonBorder?: string;
    roundedPillStartReasonHoverBackground?: string;
    roundedPillCleaningMode?: string;
    roundedPillCleaningModeBorder?: string;
    roundedPillCleaningModeHoverBackground?: string;
    roundedPillRoomNames?: string;
    roundedPillRoomNamesBorder?: string;
    roundedPillRoomNamesHoverBackground?: string;
    roundedPillZoneNames?: string;
    roundedPillZoneNamesBorder?: string;
    roundedPillZoneNamesHoverBackground?: string;
  };

  banner?: {
    warningBorder?: string;
    warningBackground?: string;
    warningIcon?: string;
    infoBorder?: string;
    infoBackground?: string;
    infoIcon?: string;
    errorBorder?: string;
    errorBackground?: string;
    errorIcon?: string;
  };

  baseSearch?: {
    searchExplantionIcon?: string;
    clearButtonIcon?: string;
  };

  bootstrap?: {
    accordionItemNotFirstBorderTop?: string;
    accordionButtonNotCollapsedBackground?: string;
  };

  companyStructureView?: {
    currentNodeIcon?: string;
    currentNodeInherited?: string;
    dataInherited?: string;
    modalDataInherited?: string;
  };

  customers?: {
    bodyLeftSideBorderRight?: string;
    bodyListPagerBorderTop?: string;
  };

  customField?: {
    bodyLeftSideBorderRight?: string;
    bodyListPagerBorderTop?: string;
    modalBodyBorderBottom?: string;
  };

  dealers?: {
    bodyLeftSideBorderRight?: string;
    bodyListPagerBorderTop?: string;
  };

  emailSend?: {
    button?: string;
    buttonBackground?: string;
    hover?: string;
    hoverBackground?: string;
    disabled?: string;
    disabledBackground?: string;
  };

  fileWorkflow?: {
    fileTitle?: string;
    fileTitleBackground?: string;
  };

  general?: {
    pageFooterBorderTop?: string;
    closeModal?: string;
    closeModalHover?: string;
    closeModalHoverBackground?: string;
    lightGreyButton?: string;
    lightGreyButtonHover?: string;
    primaryText?: string;
    supRemark?: string;
    userDisabled?: string;
    bannerYellowBackground?: string;
    bannerYellowBorderLeft?: string;
    noResultsP?: string;
    autocompleteTemplateBackground?: string;
    autocompleteTemplateDisable?: string;
    autocompleteTemplateHoverBackground?: string;
    autocompleteTemplateSelectBackground?: string;
    currentRowBacground?: string;
    noBackground?: string;
    borderWhite1?: string;
    columnTitlesBorderBottom?: string;
    textDisabled?: string;
    iconButtonDisabled?: string;
    iconButtonPrimary?: string;
    iconButtonError?: string;
    earlybytePinkColor?: string;
    secondaryTitle?: string;
    sucessColor?: string;
    warningColor?: string;
    errorColor?: string;
  };

  inputTextList?: {
    bagde?: string;
    badgeBorder?: string;
  };

  kemaroCostCalculation?: {
    bodyLeftSideBorderRight?: string;
    bodyListItemsStartTd?: string;
    bodyListItemsDefaultText?: string;
    bodyListPagerBorderTop?: string;
  };

  kemaroDashboard?: {
    economyPerformanceBorder?: string;
    economyPerformanceBoxShadow?: string;
    economyStatisticsBorder?: string;
    economyStatisticsBoxShadow?: string;
    economyCostssBorder?: string;
    economyCostsBoxShadow?: string;
    noDataContainerBackground?: string;
    noDataContainerNoData?: string;
    noDataContainerNoDataBackground?: string;
    noDataContainerNoDataBorder?: string;
    noDataContainerNoDataIcon?: string;
    robotsModalDotColor?: string;
    robotsModalDecommissioned?: string;
  };

  kemaroMissions?: {
    bodyLeftSideBorderRight?: string;
    bodyLeftSideSuccess?: string;
    bodyLeftSideError?: string;
    bodyLeftSideInternal?: string;
    bodyListPagerBorderTop?: string;
    viewSummaryBackground?: string;
    viewSummaryNoMap?: string;
    viewCustomerDealerBorderBottom?: string;
    viewRunsAccordionWithoutBottomBackground?: string;
    viewRunsAccordionWithoutBottomBorder?: string;
    viewRunsAccordionAccordionBackground?: string;
    viewRunsAccordionAccordionBorder?: string;
    viewRunsAccordionDetailBackground?: string;
    viewRunsAccordionDetailBorder?: string;
    viewRunsRunHeaderTable?: string;
    viewRunsRunDetailTable?: string;
    viewRunsRunSuccess?: string;
    viewRunsRunError?: string;
    sendReportBorderTop?: string;
    sendReportActionButton?: string;
    sendReportActionButtonBackground?: string;
    sendReportActionButtonHover?: string;
    sendReportActionButtonBackgroundHover?: string;
    sendReportActionButtonDisabled?: string;
    sendReportActionButtonBackgroundDisabled?: string;
    missionError?: string;
    missionErrorBorder?: string;
    missionErrorIcon?: string;
    newBodyTitle?: string;
    newBodyErrorBackground?: string;
    newBodyUiErrorBackground?: string;
  };

  kemaroRobots?: {
    leftSideBorderRight?: string;
    listPagerBorderTop?: string;
    companyStructureParents?: string;
    companyStructureIcon?: string;
    childParameter?: string;
    robotModel?: string;
    robotModelBorder?: string;
    krciContainerBorder?: string;
    cleanedAreaHistoryLink?: string;
    cleanedAreaHistoryLinkCurrent?: string;
    tooltipContainerBorder?: string;
    onThreshold?: string;
    outThreshold?: string;
    bugOld?: string;
    bugRecent?: string;
    accessSettingsContainerBackground?: string;
    systemDetailsContainerBackground?: string;
    errorSummaryBodyRecentTitle?: string;
    errorSummaryBodyOldTitle?: string;
    errorSummaryBodyErrorContainer?: string;
    errorSummaryBodyErrorContainerNotLastChild?: string;
    modalWarningIcon?: string;
    accessManagementModalBodyDividerBorderBottom?: string;
  };

  kemaroRobotTagsList?: {
    badge?: string;
    badgeBorder: string;
  };

  mainPanel?: {
    borderRight?: string;
    meBorderBottom?: string;
    manufacturersBorderBottom?: string;
    menuLiIcon?: string;
    menuA?: string;
    menuLiHoverBackground?: string;
    menuCurrentOptionBackground?: string;
    menuCurrentOptionA?: string;
    menuCurrentOptionLiIcon?: string;
    menuCurrentOptionHoverBackground?: string;
    smallSpanIcon?: string;
  };

  manufacturers?: {
    bodyLeftSideBorderRight?: string;
    bodyListPagerBorderTop?: string;
  };

  maxbrain?: {
    bodySuccess?: string;
    bodyError?: string;
    bodyLeftSideBorderRight?: string;
    bodyListPagerBorderTop?: string;
  };

  navigateTo?: {
    to?: string;
    icon?: string;
    hover?: string;
  };

  onBoardingAssistant?: {
    workflowsListBorderRight?: string;
    workflowVisited?: string;
    workflowLabel?: string;
    currentWorkflowLabel?: string;
    currentWorkflowLabelBackground?: string;
  };

  permissionsMatrix?: {
    containerThBorderLeft?: string;
    containerTheadBackground?: string;
    filler?: string;
    sectionTitleBackground?: string;
    rowLightBackground?: string;
    rowDarkBackground?: string;
    iconCheck?: string;
  };

  profile?: {
    bodySeparatorBorderBottom?: string;
    passwordModalErrorMessage?: string;
  };

  robotModels?: {
    bodyLeftSideBorderRight?: string;
    modalFloatButtonHoverBackground?: string;
  };

  robots?: {
    connectionBackground?: string;
  };

  search?: {
    searchExplanation?: string;
  };

  tagsDisplayer?: {
    bagde?: string;
    badgeBackground?: string;
  };

  toast?: {
    toastifyErrorBackground?: string;
    toastifySuccessBackground?: string;
    toastifyWarningBackground?: string;
    toastifyInfoBackground?: string;
  };

  treeView?: {
    iconButtonBackground?: string;
    acccordeonButton?: string;
    nodeHeaderHoverBackground?: string;
    nodeHeaderSelectedBackground?: string;
  };

  userModals?: {
    notificationConfigurationSendTest?: string;
    notificationConfigurationSendTestHoverBackground?: string;
  };

  userRoleIcon?: string;

  users?: {
    bodyLeftSideBorderRight?: string;
    bodyListPagerBorderTop?: string;
  };

  // colors defined directly in *.tsx files

  loading?: {
    //LP
    puffLoader?: string;
  };

  kemaroDashboardAmortization?: {
    //LP
    background: string;
  };

  kemaroDashboardStatisticsChart?: {
    //LP
    responsiveLine?: string;
  };

  kemaroDashboardCostChartStack?: {
    //LP
    color0?: string;
    color1?: string;
    color2?: string;
    color3?: string;
  };
}

declare module "@mui/material/styles" {
  interface Palette {
    swarm?: SwarmPaletteColor;
  }
  interface PaletteOptions {
    swarm?: SwarmPaletteColor;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    swarm: true;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    swarm: true;
  }
}

export interface AppTheme {
  palette: DeepPartial<Palette>;
  typography?: {};
}

export const color = {
  0: _default,
  1: kemaro,
  // 1: cleanfix,
};
