import { useEffect, useState } from "react";
import { Button, Dialog, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { DealerDto } from "../../../../../robotcloud-shared/resource-models";
import { ToastHelper } from "../../../components/news/Toast.helper";
import { ErrorHelper } from "../../../uiHelpers/errors.helper";
import { TableGeneric } from "../../../components/news/TableGeneric/TableGeneric";
import {
  TableGenericHelper,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../components/news";
import { OrderDto } from "../../../data/common";
import { Loading } from "../../../components/Loading";
import { DealersRequests } from "../../../data/dealers.request";
import { useTranslation } from "react-i18next";
import { TableGenericColumn } from "../../../components/news/TableGeneric/models";
import { useDealers } from "../../../hooks";
export const DealersSelectModal = (props: dealersSelectModalProps) => {
  const { t } = useTranslation();
  const {
    // State variables
    dataOrder,
    items,
    // Set state functions
    setDataOrder,
    setItems,
  } = useDealers(undefined, props.idsToRemove);
  const [dealersSelected, setDealersSelected] = useState<DealerDto[]>([]);

  const [dataOrderSelected, setDataOrderSelected] = useState<OrderDto>({
    orderColumns: "name",
    order: "Asc",
  });
  const [isWorking, setIsWorking] = useState(false);

  const columnsDefinitionLeft: TableGenericColumn[] = [
    {
      title: t("dealer.select.unselected"),
      property: "name",
      headerClassName: "ps-0",
      itemClassName: "ps-0",
    },
  ];

  const columnsDefinitionRight: TableGenericColumn[] = [
    {
      title: t("dealer.select.toAdd"),
      property: "name",
      headerClassName: "ps-0",
      itemClassName: "ps-0",
    },
  ];

  useEffect(() => {
    const getList = async () => {
      try {
        const result = await DealersRequests.getList({
          textToSearch: null,
          page: 1,
          maxItemCount: 200,
          orderBy: "Asc",
          orderColumns: "name",
          id: null,
        });

        setItems(
          result.items.filter((x) => !props.idsToRemove.some((y) => y === x.id))
        );
        setDealersSelected([]);
      } catch (error) {
        ToastHelper.errors(ErrorHelper.process(error));
      }
    };

    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.idsToRemove]);

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "DealersSelectModal",
      handleKeyboard: props.isOpenModal,
    });
  }, [props.isOpenModal]);

  TableGenericHelper.localOrder(dataOrder, items);
  TableGenericHelper.localOrder(dataOrderSelected, dealersSelected);

  const select = (item: DealerDto) => {
    setDealersSelected([...dealersSelected, item]);

    let newDealers = [...items];
    const idx = newDealers.indexOf(item);
    if (idx !== -1) {
      newDealers.splice(idx, 1);
      setItems(newDealers);
    }
  };

  const unselect = (item: DealerDto) => {
    setItems([...items, item]);

    let newDealersSelected = [...dealersSelected];
    const idx = newDealersSelected.indexOf(item);
    if (idx !== -1) {
      newDealersSelected.splice(idx, 1);
      setDealersSelected(newDealersSelected);
    }
  };

  const handleSave = async () => {
    setIsWorking(true);
    await props.onSave(dealersSelected.map((x) => x.id));
    setIsWorking(false);
  };

  return (
    <Dialog
      className="application-modal"
      open={props.isOpenModal}
      maxWidth="md"
      onClose={() => props.onCancel()}
    >
      <div className="modal-content">
        <Stack>
          <Box className="application-modal-header">
            <h3>{t("dealer.select.title")}</h3>
          </Box>
          <Box className="application-modal-body">
            {isWorking ? (
              <Loading isLoading={isWorking} />
            ) : (
              <table className="w-100">
                <tbody>
                  <tr>
                    <td className="w-50 v-top">
                      <TableGeneric
                        tableClassName="w-100"
                        columns={columnsDefinitionLeft}
                        items={items}
                        idProperty="id"
                        headerClick={(header, itemsOrder) =>
                          TableGenericHelper.localOrder(
                            itemsOrder,
                            items,
                            setDataOrder
                          )
                        }
                        selectionChanged={select}
                        itemsOrder={dataOrder}
                      ></TableGeneric>
                    </td>
                    <td className="one-button-container"></td>
                    <td className="w-50 v-top">
                      <TableGeneric
                        tableClassName="w-100"
                        columns={columnsDefinitionRight}
                        items={dealersSelected}
                        idProperty="id"
                        headerClick={(header, itemsOrder) =>
                          TableGenericHelper.localOrder(
                            itemsOrder,
                            dealersSelected,
                            setDataOrderSelected
                          )
                        }
                        selectionChanged={unselect}
                        itemsOrder={dataOrder}
                      ></TableGeneric>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Box>
          {isWorking ? (
            <Box className="application-modal-footer"></Box>
          ) : (
            <Box className="application-modal-footer">
              <Button
                variant="contained"
                className="button-with-text"
                color="primary"
                onClick={() => handleSave()}
              >
                {t("common.buttons.save")}
              </Button>

              <Button
                className="modal-cancel-button button-with-text"
                variant="outlined"
                color="primary"
                onClick={(x) => props.onCancel()}
              >
                {t("common.buttons.cancel")}
              </Button>
            </Box>
          )}
        </Stack>
      </div>
    </Dialog>
  );
};

interface dealersSelectModalProps {
  idsToRemove: string[];
  isOpenModal: boolean;
  onSave: (dealerIds: string[]) => Promise<void>;
  onCancel: () => void;
}
