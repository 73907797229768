import { TooltipContainer } from "../TooltipContainer";
import { useState } from "react";
import { GeneralFunctions } from "../../../views/generalFunctions";
import { TextInput } from "../TextInput";
import { TooltipButton } from "../TooltipButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { ToastHelper } from "../Toast.helper";
import "./inputTextList.scss";
import useTheme from "@mui/material/styles/useTheme";

export const InputTextList = ({
  addToolTip,
  removeTootTip,
  texts,
  itemsShowAs = "List",
  regularExpression,
  invalidText,
  onChange,
}: InputTextListProps) => {
  const [currentText, setCurrentText] = useState("");

  const addText = () => {
    const text = currentText.trim();

    if (regularExpression && !regularExpression.test(text)) {
      ToastHelper.error(invalidText ?? "Invalid text");
      return;
    }

    if (!texts.some((x) => x === text) && !GeneralFunctions.isBlank(text)) {
      const sortedTexts = [...texts, text].sort((a, b) => {
        if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) return 1;
        if (b.toLocaleLowerCase() > a.toLocaleLowerCase()) return -1;
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      });

      onChange(sortedTexts);
    }

    setCurrentText("");
  };

  const addTextKeyDown = (key: string) => {
    if (key !== "Enter") return;
    addText();
  };

  const removeText = (text: string) => {
    const filteredTexts = [...texts.filter((x) => x !== text)];
    onChange(filteredTexts);
  };

  const theme = useTheme();

  return (
    <div className="row input-text-list">
      <div className={`col-11 ${itemsShowAs === "List" ? "mb-3" : "mb-0"} `}>
        <TextInput
          value={currentText}
          ret={(x) => setCurrentText(x)}
          onKeyDown={addTextKeyDown}
        />
      </div>
      <div className={`col-1 pt-2 ${itemsShowAs === "List" ? "mb-3" : "mb-0"}`}>
        <TooltipButton
          className="button"
          tooltip={addToolTip}
          onClick={addText}
        >
          <AddOutlinedIcon color="primary" />
        </TooltipButton>
      </div>

      {itemsShowAs === "List" && (
        <div className="col-12 mb-3 text-container">
          <table className="table table-borderless table-striped">
            <tbody>
              {texts.map((x, idx) => (
                <tr key={idx}>
                  <td className="align-content-center">{x}</td>
                  <td className="td-delete">
                    <TooltipContainer text={removeTootTip}>
                      <DeleteForever
                        className="icon-as-buttom"
                        color="error"
                        onClick={() => removeText(x)}
                      />
                    </TooltipContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {itemsShowAs === "InLine" && (
        <div className="col-12 mb-3 text-container">
          {texts.map((x) => (
            <span
              key={x}
              className="badge me-3 mb-2"
              style={{
                color: theme.palette.swarm?.inputTextList?.bagde,
                border: theme.palette.swarm?.inputTextList?.badgeBorder,
              }}
              onClick={() => removeText(x)}
            >
              {x}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

interface InputTextListProps {
  addToolTip: string;
  removeTootTip: string;
  texts: string[];
  itemsShowAs?: InputTextListItemsShow;
  regularExpression?: RegExp;
  invalidText?: string;
  onChange: (texts: string[]) => void;
}

type InputTextListItemsShow = "List" | "InLine";
