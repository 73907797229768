import { useCallback, useEffect, useState } from "react";
import {
  CustomFieldFilterCriteriaDto,
  CustomFieldListDto,
} from "../../../robotcloud-shared/resource-models";
import { CustomFieldRequests } from "../data/customField.request";
import { ToastHelper } from "../components/news";
import { ErrorHelper } from "../uiHelpers/errors.helper";

export const useCustomField = (
  filterCriteria: CustomFieldFilterCriteriaDto
) => {
  const [field, setField] = useState<CustomFieldListDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const stableFilterCriteria = useCallback(
    () => ({
      ...filterCriteria,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterCriteria.type,
      filterCriteria.id,
      filterCriteria.textToSearch,
      filterCriteria.page,
      filterCriteria.maxItemCount,
      filterCriteria.orderBy,
      filterCriteria.orderColumns,
    ]
  );

  useEffect(() => {
    setIsLoading(true);
    CustomFieldRequests.getList(stableFilterCriteria())
      .then((data) => {
        setField(data.items);
      })
      .catch((error) => {
        ToastHelper.errors(ErrorHelper.process(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [stableFilterCriteria]);
  return { field, isLoading };
};
