import { useEffect, useState } from "react";
import { TextInput } from "./news/TextInput";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import { useCountries } from "../hooks";
import { useTranslation } from "react-i18next";

interface ZipCodeInputProps {
  countryId: string; // ISO 3166-1 alpha-2 country code
  zipCode: string;
  setZipCode: (zipCode: string) => void;
  isValid: boolean;
  setIsValid: (isValid: boolean) => void;
  disabled?: boolean;
}

export const ZipCodeInput = ({
  countryId,
  zipCode,
  setZipCode,
  isValid,
  setIsValid,
  disabled,
}: ZipCodeInputProps) => {
  const { countries } = useCountries();
  const [isDisabled, setIsDisabled] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const country = countries?.find((x) => x.id === countryId);
    if (country?.iso2) {
      if (postcodeValidatorExistsForCountry(country?.iso2)) {
        setIsDisabled(false);
        setIsValid(postcodeValidator(zipCode, country?.iso2));
      } else {
        setZipCode("");
        setIsValid(true);
        setIsDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode, countryId]);

  return (
    <>
      <TextInput
        value={zipCode}
        ret={setZipCode}
        disabled={isDisabled || disabled}
      />
      {!isValid && zipCode.length > 0 && (
        <div className="text-danger">{t("tags.invalidField")}</div>
      )}
    </>
  );
};
