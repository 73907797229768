import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ProcessFilterCriteriaDto,
  ProcessReducedDto,
  WorkflowReducedDto,
  WorkflowTarget,
  WorkflowTranslatedDataDto,
} from "../../../../robotcloud-shared/resource-models";
import { ApplicationBar } from "../../components/news";
import { ProcessesRequests } from "../../data/processes.request";
import AuthorizationContext from "../auth/authorizationContext";
import { KemaroAdministrationTabsNav } from "../kemaro/administration/KemaroAdministrationTabsNav";
import "./onBoarding.scss";
import OnBoardingContext from "./OnBoardingContext";
import { WorkflowPanel } from "./Workflow/WorkflowPanel";
import { WorkflowView } from "./Workflow/WorkflowView";

export const OnBoarding = () => {
  const { t } = useTranslation();
  const [currentSelectedItem, setCurrentSelectedItem] = useState<
    WorkflowReducedDto | undefined
  >(undefined);
  const [currentSelectedItemTranslations, setCurrentSelectedItemTranslations] =
    useState<WorkflowTranslatedDataDto[]>([]);

  const [fetchUsersEvent, setFetchUsersEvent] = useState<boolean>(true);
  const [fetchCompaniesEvent, setFetchCompaniesEvent] = useState<boolean>(true);
  const refreshItems = (target: WorkflowTarget) => {
    if (target === "user") {
      setFetchUsersEvent(!fetchUsersEvent);
    }

    if (target === "company") {
      setFetchCompaniesEvent(!fetchCompaniesEvent);
    }
  };
  const [process, setProcess] = useState<ProcessReducedDto>();
  const [currentTarget, setCurrentTarget] = useState<WorkflowTarget>("user");
  const { me } = useContext(AuthorizationContext);

  useEffect(() => {
    if (me?.currentManufacturerId) {
      const filter: ProcessFilterCriteriaDto = {
        maxItemCount: 1,
        page: 1,
        manufacturerId: me?.currentManufacturerId ?? null,
        behaviour: "compliance",
        orderBy: "Asc",
        orderColumns: "Name",
        id: null,
      };
      ProcessesRequests.getList(filter).then((result) => {
        if (result.totalItems > 0) {
          setProcess(result.items[0]);
        } else {
          setProcess(undefined);
        }
      });
    }
  }, [me?.currentManufacturerId]);

  return (
    <OnBoardingContext.Provider
      value={{
        process,
        currentSelectedItem,
        setCurrentSelectedItem,
        currentSelectedItemTranslations,
        setCurrentSelectedItemTranslations,
        refreshItems,
        manufacturerName: me?.currentManufacturerName ?? "",
        currentTarget,
        setCurrentTarget,
      }}
    >
      <div className="page-content">
        <div className="page-header">
          <ApplicationBar
            title={t("applicationBar.administration.title")}
            canSearch={false}
          />
        </div>

        <KemaroAdministrationTabsNav currentTab="on-boarding" />

        <div className="page-body d-flex on-boarding-body">
          <div className="on-boarding-left-side">
            <WorkflowPanel fetchEvent={fetchUsersEvent} target={"user"} />
            <div className="border-top"></div>
            <WorkflowPanel
              fetchEvent={fetchCompaniesEvent}
              target={"company"}
            />
          </div>

          <div className="view-container">
            {currentSelectedItem && <WorkflowView />}
          </div>
        </div>
      </div>
    </OnBoardingContext.Provider>
  );
};
