import { useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Box from "@mui/material/Box";
import { Login } from "./views/auth/login";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import {
  ApiLoginCompleteDto,
  ManufacturerConfigurationDto,
  ManufacturerReducedDto,
} from "../../robotcloud-shared/resource-models";
import AuthorizationContext from "./views/auth/authorizationContext";
import { Unauthenticated } from "./views/auth/unauthenticated";
import { Authenticated } from "./views/auth/authenticated";
import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { BASE_URL } from "./data/axios.requests";
import MsalProviderSelector from "./MsalProviderSelector";
import { Pages } from "./views/Pages";
import VersionWarning from "./components/news/Version/VersionWarning";
import { useKemaroAcademySection, useSignalRConnection } from "./hooks";
import { ForgotPasswordConfirm } from "./views/auth/forgotPasswordConfirm";
import { Logout } from "./views/auth/logout";

function App() {
  const [allowedManufacturers, setAllowedManufacturers] = useState<
    ManufacturerReducedDto[] | undefined
  >();
  const [currentManufacturer, setCurrentManufacturer] = useState<
    ManufacturerReducedDto | undefined
  >(undefined);
  const [connectionIds, setConnectionIds] = useState("--");

  const [me, setMe] = useState<ApiLoginCompleteDto | undefined>(undefined);
  const [configuration, setConfiguration] = useState<
    ManufacturerConfigurationDto | undefined
  >(undefined);

  const {
    signalRConnection,
    setSignalRConnection,
    kemaroMissionChange,
    setKemaroMissionChange,
  } = useSignalRConnection(setCurrentManufacturer, me, currentManufacturer);

  const {
    isKemaroAcademySection,
    setIsKemaroAcademySection,
    isManufacturerAdmin,
    setIsManufacturerAdmin,
  } = useKemaroAcademySection(me);

  useEffect(() => {
    if (!me || !currentManufacturer) {
      return;
    }

    const localConnectionIds = `${me.id}_${currentManufacturer.id}`;
    if (localConnectionIds === connectionIds) {
      return;
    }

    if (
      signalRConnection &&
      signalRConnection.state === HubConnectionState.Connected
    ) {
      signalRConnection.stop();
    }

    setConnectionIds(localConnectionIds);
    setSignalRConnection(getHubConnection(me.id, currentManufacturer.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, currentManufacturer, signalRConnection, connectionIds]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const [currentUrl] = useState(window.location.href);

  return (
    <>
      <MsalProviderSelector>
        <QueryClientProvider client={queryClient}>
          <AuthorizationContext.Provider
            value={{
              me,
              setMe,
              allowedManufacturers,
              setAllowedManufacturers,
              currentManufacturer,
              setCurrentManufacturer,
              configuration,
              setConfiguration,
              kemaroMissionChange,
              setKemaroMissionChange,
              isKemaroAcademySection,
              setIsKemaroAcademySection,
              isManufacturerAdmin,
              setIsManufacturerAdmin,
            }}
          >
            <UnauthenticatedTemplate>
              <Unauthenticated />
              <Routes>
                {
                  // This routes are also configured in MsalProviderSelector.tsx, the login route is duplicated
                  // here redirect to the login page when the user is unauthenticated.
                  // Password reset route is also shown on the login page with the same purpose.
                }
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/new-login" element={<Login />} />
                <Route
                  path="/forgot-password-confirm"
                  element={<ForgotPasswordConfirm />}
                />
                <Route path="/logout" element={<Logout />} />
              </Routes>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <>
                <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                  <VersionWarning></VersionWarning>
                  <Box
                    className="main-container"
                    display={"flex"}
                    height={"100%"}
                  >
                    <Authenticated />
                    <Pages
                      manufacturers={allowedManufacturers ?? []}
                      onManufacturerChange={(x: ManufacturerReducedDto) =>
                        setCurrentManufacturer(x)
                      }
                      me={me}
                      initialUrl={currentUrl}
                    />
                  </Box>
                </Box>
              </>
            </AuthenticatedTemplate>
          </AuthorizationContext.Provider>
        </QueryClientProvider>
      </MsalProviderSelector>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="colored"
      />
    </>
  );
}

export default App;

const getHubConnection = (userId: string, currentComapny: string) => {
  let baseSignalRUrl = BASE_URL;
  // We need to override the base url for proxying the signalR connection
  if (process.env.REACT_APP_STAGE === "CloudProd") {
    baseSignalRUrl = "https://rc.eb8.ch";
  }
  if (process.env.REACT_APP_STAGE === "CloudDemo") {
    baseSignalRUrl = "https://rc.demo.eb8.ch";
  }
  if (process.env.REACT_APP_STAGE === "CloudDev") {
    baseSignalRUrl = "https://rc.dev.eb8.ch";
  }

  const hubConnection = new HubConnectionBuilder()
    .withUrl(
      `${baseSignalRUrl}/hubs/swarmHub?userId=${userId}&currentCompany=${currentComapny}`
    )
    .withAutomaticReconnect()
    .build();

  // https://localhost:5001
  // ${BASE_URL}
  return hubConnection;
};
