import { useTranslation } from "react-i18next";
import { TooltipButton, YesNoOkDialog } from "../../../../../components/news";
import { useEffect, useState } from "react";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useDashboardLastExecutionState } from "../hooks";
import { GeneralFunctions } from "../../../../generalFunctions";
import useTheme from "@mui/material/styles/useTheme";

import { useDashboardExecutionHistory } from "../hooks/useDashboardExecutionHistory";
import {
  ExecutionHistoryModalContent,
  colorFromStatus,
} from "./ExecutionHistoryModalContent";

export const ExecutionHistoryButton = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [showModal, setShowModal] = useState(false);
  const [iconColor, setIconColor] = useState(
    theme.palette.swarm?.general?.earlybytePinkColor
  );
  const [buttonTooltip, setButtonTooltip] = useState(
    t("costCalculation.executionHistory.checking")
  );

  const { lastState } = useDashboardLastExecutionState();
  const { isGettingExecutionHistory, executionHistory } =
    useDashboardExecutionHistory(showModal);

  useEffect(() => {
    setIconColor(colorFromStatus(lastState?.status, theme));

    setButtonTooltip(
      GeneralFunctions.dateToTimeDateString(lastState.when) +
        " " +
        t(
          `costCalculation.executionHistory.${lastState.status
            .toLowerCase()
            .replace(" ", "")}`
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastState]);

  return (
    <>
      <TooltipButton
        aria-label={buttonTooltip}
        tooltip={buttonTooltip}
        onClick={() => setShowModal(true)}
      >
        <LightbulbOutlinedIcon
          className="earlybyte-pink-color"
          sx={{ color: iconColor }}
        />
      </TooltipButton>

      {showModal && (
        <YesNoOkDialog
          open={true}
          size="lg"
          type="ok"
          title={t("costCalculation.executionHistory.modalTitle")}
          modalClassName="dashboard-execution-history-modal"
          isWorking={isGettingExecutionHistory}
          onNo={() => setShowModal(false)}
          onOk={() => setShowModal(false)}
          okText={t("common.buttons.cancel")}
        >
          <ExecutionHistoryModalContent
            isGettingExecutionHistory={isGettingExecutionHistory}
            executionHistory={executionHistory}
          />
        </YesNoOkDialog>
      )}
    </>
  );
};
