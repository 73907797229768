import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { Loading } from "../../../../components/Loading";
import {
  ToastHelper,
  tableGenericHandleKeyboardSubjectManager,
} from "../../../../components/news";
import { ErrorHelper } from "../../../../uiHelpers/errors.helper";
import { useTranslation } from "react-i18next";
import { KemaroMissionsNewBody } from "./KemaroMissionsNewBody";
import { TimeZonesRequest } from "../../../../data/timeZones.request";
import { useCustomField } from "../../../../hooks";

export const KemaroMissionsNewDialog = (
  props: KemaroMissionsNewDialogProps
) => {
  const { t } = useTranslation();

  const [isIanaLoading, setIsIanaLoading] = useState(false);
  const { field: errorCodes, isLoading: loadingErrorCodes } = useCustomField({
    type: "ErrorCode",
    id: null,
    textToSearch: null,
    page: 1,
    maxItemCount: 10000,
    orderBy: null,
    orderColumns: null,
  });
  const {
    field: missionStartStopReason,
    isLoading: loadingMissionStartStopReason,
  } = useCustomField({
    type: "MissionStartStopReason",
    id: null,
    textToSearch: null,
    page: 1,
    maxItemCount: 10000,
    orderBy: null,
    orderColumns: null,
  });
  const { field: cleaningMode, isLoading: loadingdCleaningMode } =
    useCustomField({
      type: "CleaningMode",
      id: null,
      textToSearch: null,
      page: 1,
      maxItemCount: 10000,
      orderBy: null,
      orderColumns: null,
    });
  const [ianaList, setIanaList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(
    loadingErrorCodes ||
      loadingMissionStartStopReason ||
      loadingdCleaningMode ||
      isIanaLoading
  );

  useEffect(() => {
    tableGenericHandleKeyboardSubjectManager.setData({
      caller: "KemaroMissionsNewDialog",
      handleKeyboard: props.openPopup,
    });
  }, [props.openPopup]);

  useEffect(() => {
    setIsIanaLoading(true);
    TimeZonesRequest.getIanaList()
      .then((data) => {
        setIanaList(data);
      })
      .catch((error) => {
        ToastHelper.errors(ErrorHelper.process(error));
      })
      .finally(() => {
        setIsIanaLoading(false);
      });
  }, []);

  return (
    <Dialog
      className="application-modal"
      open={props.openPopup}
      maxWidth="lg"
      onClose={() => props.onCancel()}
    >
      <div className="modal-content">
        <div className="application-modal-header">
          <table className="w-100">
            <tbody>
              <tr>
                <td>
                  <h3>{t("mission.add.title")}</h3>
                </td>
                <td className=" text-end"></td>
              </tr>
            </tbody>
          </table>
        </div>
        {isLoading && (
          <div className="application-modal-body">
            <Loading isLoading={isLoading} />
          </div>
        )}

        {!isLoading && (
          <KemaroMissionsNewBody
            errorCodes={errorCodes}
            missionStartStopReason={missionStartStopReason}
            cleaningMode={cleaningMode}
            ianaList={ianaList}
            setIsWorking={setIsLoading}
            onCreated={props.onCreated}
            onCancel={props.onCancel}
            handleCancel={() => props.onCancel()}
          />
        )}
      </div>
    </Dialog>
  );
};

interface KemaroMissionsNewDialogProps {
  openPopup: boolean;
  onCreated: (id: string) => void;
  onCancel: () => void;
}
